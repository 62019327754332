import Vue from "vue";
import LocaleMixin from "@/mixins/locale";
import { formatTime } from "@/helpers/dateTimeHelper";
export default Vue.extend({
    mixins: [LocaleMixin],
    data() {
        return {
            data: {},
            tooltipContent: "",
        };
    },
    computed: {
        getChipValues() {
            const appointment = this.data;
            const chipValues = [];
            if (appointment.CanceledWithoutChargeCustomers?.length > 0 || appointment.CanceledWithChargeCustomers?.length > 0) {
                chipValues.push({
                    cssClass: appointment.CanceledWithChargeCustomers?.length > 0 ? "e-warning" : "e-success",
                    leadingIcon: "fa-solid fa-ban",
                    data: { type: "cancellation" }
                });
            }
            chipValues.push({
                //text: appointment.PaymentStatus == 1 ? "Non pagato" : appointment.PaymentStatus == 2 || appointment.PaymentStatus == 3 ? "Pagato" : "Sconosciuto",
                cssClass: appointment.PaymentStatus == 1 ? "e-danger" : appointment.PaymentStatus == 2 ? "e-warning" : appointment.PaymentStatus == 3 ? "e-success" : "",
                leadingIcon: "far fa-credit-card",
                data: { type: "paymentStatus" }
            });
            return chipValues;
        }
    },
    methods: {
        tooltipOnBeforeRender(args) {
            switch (args.target.dataset["type"]) {
                case "paymentStatus":
                    this.tooltipContent = this.data.PaymentStatus == 1 ? "Non pagato" :
                        this.data.PaymentStatus == 2 || this.data.PaymentStatus == 3 ?
                            (this.data.CustomerIds != null && this.data.CustomerIds.length > 0 ? "Pagato da:" : "Pagato") :
                            "Stato sconosciuto";
                    if (this.data.CustomerIds != null && this.data.CustomerIds.length > 0 && this.data.PaidCustomers.length > 0) {
                        for (const customerName of this.data.PaidCustomers)
                            this.tooltipContent += `<div>${customerName}</div>`;
                    }
                    break;
                case "cancellation":
                    this.tooltipContent = "";
                    if (this.data.CanceledWithoutChargeCustomers?.length > 0) {
                        this.tooltipContent += "<div>Senza addebito:</div>";
                        for (const customerName of this.data.CanceledWithoutChargeCustomers)
                            this.tooltipContent += `<div>${customerName}</div>`;
                    }
                    if (this.data.CanceledWithoutChargeCustomers?.length > 0 && this.data.CanceledWithChargeCustomers?.length > 0)
                        this.tooltipContent += "<div>&nbsp;</div>";
                    if (this.data.CanceledWithChargeCustomers?.length > 0) {
                        this.tooltipContent += "<div>Con addebito:</div>";
                        for (const customerName of this.data.CanceledWithChargeCustomers)
                            this.tooltipContent += `<div>${customerName}</div>`;
                    }
            }
        },
        formatTime(date) {
            return formatTime(this.appLocale, date);
        }
    },
    beforeMount() {
        if (this.data.RecurrenceRule != null && this.data.RecurrenceRule != "") {
            const events = this.$parent.getEvents();
            const childAppointment = events.find(event => event.ParentId === this.data.Id &&
                event.StartTime.getTime() === this.data.StartTime.getTime() &&
                event.EndTime.getTime() === this.data.EndTime.getTime());
            // TODO: Check if the child appointment exists
            this.data.ChildId = childAppointment.Id;
            this.data.PaymentStatus = childAppointment.PaymentStatus;
            this.data.PaidCustomers = childAppointment.PaidCustomers;
        }
    }
});
