import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import LocaleMixin from "@/mixins/locale";
import { addHours, getNameOfWeek, getToday, getTotalHours } from "@/helpers/dateTimeHelper";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [SpinnerMixin, LocaleMixin],
    data() {
        return {
            nextId: 0,
            officeId: null,
            offices: [],
            services: [],
            rooms: [],
            equipments: [],
            employees: [],
            duration: addHours(getToday(false), 1),
            isMobile: true,
            data: {
                officeId: null,
                serviceId: null,
                roomIds: [],
                equipmentIds: [],
                employeeIds: [],
                duration: 0,
                hours: []
            },
            fields: { text: "name", value: "id" },
            appointments: {
                previous: undefined,
                next: undefined,
                data: []
            },
            firstAvailabilityResults: {
                next: null,
                previous: null,
                slots: []
            },
            searchValidations: {
                rules: {
                    officeId: {
                        required: true
                    },
                    serviceId: {
                        required: true
                    },
                    duration: {
                        required: [
                            (args) => {
                                const value = args.element.ej2_instances[0].valueWithMinutes;
                                return getTotalHours(value) > 0;
                            }
                        ]
                    },
                    roomIds: {
                        required: [
                            (args) => {
                                const serviceField = args.element.form.querySelector("#serviceId").ej2_instances[0], service = serviceField.dataSource.find(service => service.id == serviceField.value), roomIds = args.element.form.querySelector("#roomIds").ej2_instances[0].value;
                                if (service?.roomMandatory)
                                    return roomIds.length > 0;
                                return true;
                            }
                        ]
                    },
                    equipmentIds: {
                        required: [
                            (args) => {
                                const serviceField = args.element.form.querySelector("#serviceId").ej2_instances[0], service = serviceField.dataSource.find(service => service.id == serviceField.value), equipmentIds = args.element.form.querySelector("#equipmentIds").ej2_instances[0].value;
                                if (service?.equipmentMandatory)
                                    return equipmentIds.length > 0;
                                return true;
                            }
                        ]
                    }
                }
            },
            daysOfWeek: [
                { id: 1, name: getNameOfWeek(1, this.$i18n.locale) },
                { id: 2, name: getNameOfWeek(2, this.$i18n.locale) },
                { id: 3, name: getNameOfWeek(3, this.$i18n.locale) },
                { id: 4, name: getNameOfWeek(4, this.$i18n.locale) },
                { id: 5, name: getNameOfWeek(5, this.$i18n.locale) },
                { id: 6, name: getNameOfWeek(6, this.$i18n.locale) },
                { id: 0, name: getNameOfWeek(0, this.$i18n.locale) }
            ],
            spinnerTarget: null,
        };
    },
    watch: {
        duration() {
            this.data.duration = getTotalHours(this.duration);
        }
    },
    methods: {
        setOfficeId(officeId) {
            this.officeId = officeId;
            if (this.hasMultipleOffices) {
                setTimeout(() => {
                    this.data.officeId = officeId;
                }, 300);
            }
        },
        async search() {
            try {
                this.showSpinner(this.spinnerTarget);
                this.firstAvailabilityResults = await this.$store.dispatch("calendar/searchFirstAvailability", {
                    data: this.data
                });
            }
            catch (errors) {
                this.$toast.showWarningToast(this.$t('calendar.firstAvailability.title'), errors[0].message);
                throw errors;
            }
            finally {
                this.hideSpinner(this.spinnerTarget);
            }
        },
        async searchByUrl(url) {
            try {
                this.showSpinner(this.spinnerTarget);
                this.firstAvailabilityResults = await this.$store.dispatch("calendar/searchFirstAvailabilityByQuery", {
                    data: url
                });
                this.searchSuccess();
            }
            catch (errors) {
                this.$root.$emit("appointmentSearchError", errors);
                this.$toast.showWarningToast(this.$t('calendar.firstAvailability.title'), errors[0].message);
            }
            finally {
                this.hideSpinner(this.spinnerTarget);
            }
        },
        searchSuccess() {
            this.appointments = {
                previous: this.firstAvailabilityResults.previous,
                next: this.firstAvailabilityResults.next,
                data: this.mapSlots(this.firstAvailabilityResults.slots)
            };
            this.$root.$emit("appointmentSearchResult", this.appointments);
        },
        mapSlots(slots) {
            return slots.map((slot, index) => {
                return {
                    id: -index,
                    startTime: slot.startDateTime,
                    endTime: slot.endDateTime,
                    officeId: slot.officeId,
                    officeName: slot.officeName,
                    serviceId: slot.serviceId,
                    serviceName: slot.serviceName,
                    employeeId: slot.employeeId,
                    employeeFullName: slot.employeeFullName,
                    equipmentId: slot.equipmentId,
                    equipmentName: slot.equipmentName,
                    roomId: slot.roomId,
                    roomName: slot.roomName
                };
            });
        },
        cancel() {
            this.firstAvailabilityResults = {
                next: null,
                previous: null,
                slots: []
            };
            this.clearFields();
            this.$root.$emit("cancelFirstAvailabilityDialog");
        },
        clearFields() {
            for (let i = 1; i <= 2; i++) {
                setTimeout(() => {
                    // Reset data
                    this.nextId = 0;
                    this.data.equipmentIds = [];
                    this.data.roomIds = [];
                    this.data.employeeIds = [];
                    this.data.serviceId = null;
                    if (this.hasMultipleOffices)
                        this.data.officeId = this.officeId;
                    this.data.duration = 0;
                    // Remove all hours and add a new one
                    this.data.hours.forEach(hour => this.removeHour(hour.id));
                    this.addHour();
                    // Reset duration
                    this.duration = addHours(getToday(false), 1);
                }, 200);
            }
        },
        addHour(data) {
            this.nextId--;
            const newHour = {
                id: this.nextId,
                dayOfWeek: data?.dayOfWeek || null,
                startTime: data?.startTime || null,
                endTime: data?.endTime || null
            };
            this.data.hours.push(newHour);
            this.addValidation(newHour.id);
        },
        copyHour(id) {
            const hour = this.data.hours.find(hour => hour.id === id);
            this.addHour(hour);
        },
        removeHour(id) {
            const hour = this.data.hours.find(workHour => workHour.id == id);
            this.data.hours = this.data.hours.filter(hour => hour.id != id);
            if (hour != undefined) {
                this.removeValidation(id);
            }
        },
        addValidation(id) {
            const form = this.$refs["first-availability-dialog-form"];
            form.addRules(`dayOfWeek_${id}`, {
                required: [
                    (args) => {
                        const inputVal = document.getElementById(`dayOfWeek_${id}`).ej2_instances[0].value;
                        return inputVal != null;
                    },
                    this.$t('shared.requiredField')
                ]
            });
            form.addRules(`startTime_${id}`, {
                required: [
                    (args) => {
                        const inputVal = document.getElementById(`startTime_${id}`).ej2_instances[0].value;
                        return inputVal != null;
                    },
                    this.$t('shared.requiredField')
                ]
            });
            form.addRules(`endTime_${id}`, {
                required: [
                    (args) => {
                        const inputVal = document.getElementById(`endTime_${id}`).ej2_instances[0].value;
                        return inputVal != null;
                    },
                    this.$t('shared.requiredField')
                ],
                dateAfter: [
                    (args) => {
                        const startTime = args.element.form.querySelector(`#startTime_${id}`)
                            .ej2_instances[0].value;
                        const endTime = args.element.form.querySelector(`#endTime_${id}`)
                            .ej2_instances[0].value;
                        return startTime < endTime;
                    },
                    this.$t("calendar.endDateTimeMustBeGreaterThanStartDate")
                ]
            });
        },
        removeValidation(id) {
            const form = this.$refs["first-availability-dialog-form"];
            form.removeRules(`dayOfWeek_${id}`);
            form.removeRules(`startTime_${id}`);
            form.removeRules(`endTime_${id}`);
        },
        onResize() {
            this.isMobile = window.innerWidth <= 500;
        }
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
        servicesData() {
            const services = this.services.filter(service => service.deletedAt == null);
            if (this.data.officeId == null)
                return services;
            return services.filter(service => service.offices.some(office => office.officeId == this.data.officeId));
        },
        roomsData() {
            const rooms = this.rooms.filter(room => room.deletedAt == null);
            if (this.data.officeId == null)
                return rooms;
            return rooms.filter(room => room.officeId === this.data.officeId);
        },
        equipmentsData() {
            const equipments = this.equipments.filter(equipment => equipment.deletedAt == null);
            if (this.data.officeId == null)
                return equipments;
            return equipments.filter(equipment => equipment.officeId === this.data.officeId);
        },
        employeesData() {
            let employees = this.employees.filter(employee => employee.deletedAt == null);
            if (this.data.officeId == null)
                return employees;
            employees = employees.filter(employee => employee.services?.some(employeeService => employeeService.officeId === this.data.officeId));
            if (this.data.serviceId == null)
                return employees;
            return employees.filter(employee => employee.services?.some(employeeService => employeeService.serviceId === this.data.serviceId));
        },
        isServiceVisible() {
            return this.data.officeId != null;
        },
        isDurationVisible() {
            return this.data.serviceId != null;
        },
        isEmployeesVisible() {
            return this.data.serviceId != null;
        },
        isRoomVisible() {
            if (this.data.serviceId == null) {
                return false;
            }
            const service = this.services.find((service) => service.id === this.data.serviceId);
            return service?.roomRequired ?? false;
        },
        isEquipmentVisible() {
            if (this.data.serviceId == null) {
                return false;
            }
            const service = this.services.find((service) => service.id === this.data.serviceId);
            return service?.equipmentRequired ?? false;
        }
    },
    beforeMount() {
        if (!this.hasMultipleOffices) {
            this.officeId = this.getSingleOfficeId;
            this.data.officeId = this.getSingleOfficeId;
        }
        this.$root.$on("officeIdChanged", this.setOfficeId);
        this.$root.$on("findAppointment", this.cancel);
        this.$root.$on("appointmentPreviousPage", this.searchByUrl);
        this.$root.$on("appointmentNextPage", this.searchByUrl);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        this.$root.$off("officeIdChanged", this.setOfficeId);
        this.$root.$off("findAppointment", this.cancel);
        this.$root.$off("appointmentPreviousPage", this.searchByUrl);
        this.$root.$off("appointmentNextPage", this.searchByUrl);
    },
    async mounted() {
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.spinnerTarget = document.getElementById("first-availability-dialog-form");
        this.createSpinner(this.spinnerTarget);
        this.offices = await this.$store.dispatch("office/getAll", {
            load: false
        });
        this.offices = this.offices.filter(office => office.id != null);
        this.services = await this.$store.dispatch("service/getAll", {
            load: false
        });
        this.rooms = await this.$store.dispatch("room/getAll", {
            load: false
        });
        this.rooms = this.rooms.filter(room => room.deletedAt == undefined || this.data.roomIds.includes(room.id));
        this.equipments = await this.$store.dispatch("equipment/getAll", {
            load: false
        });
        this.employees = await this.$store.dispatch("employee/getAll", {
            load: false
        });
        this.employees.forEach((employee) => (employee.fullName = `${employee.name} ${employee.surname}`));
        this.clearFields();
        if (this.data.hours.length === 0)
            this.addHour();
    }
});
