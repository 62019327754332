import Vue from "vue";
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
MultiSelect.Inject(CheckBoxSelection);
Vue.use(MultiSelectPlugin);
import SpinnerMixin from "@/mixins/spinner";
import { mapGetters } from "vuex";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { addDays } from "@/helpers/dateTimeHelper";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            data: {
                query: "",
                startDate: null,
                endDate: null,
                officeIds: [],
                customerIds: [],
                serviceIds: [],
                employeeIds: []
            },
            officeId: null,
            offices: [],
            services: [],
            customers: [],
            employees: [],
            appointmentsResult: { data: [] },
            fields: { text: "name", value: "id" }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
        servicesData() {
            if (this.data.officeIds.length === 0)
                return this.services;
            return this.services.filter(service => this.data.officeIds.some(officeId => service.offices.some(office => office.officeId === officeId)));
        },
        employeesData() {
            if (this.data.officeIds.length === 0)
                return this.employees;
            return this.employees.filter(employee => this.data.officeIds.some(officeId => employee.services.some(service => service.officeId === officeId)));
        }
    },
    methods: {
        setOfficeId(officeId) {
            this.officeId = officeId;
            if (this.hasMultipleOffices) {
                setTimeout(() => {
                    this.data.officeIds = officeId != null ? [officeId] : [];
                }, 300);
            }
        },
        getFilteringQuery(text, fieldName = "name", operator = "contains") {
            const terms = text.split(" ").filter((t) => t != "");
            if (terms.length == 0)
                return null;
            let predicate = null;
            for (const term of terms) {
                const termPredicate = new Predicate(fieldName, operator, term, true, true);
                predicate = predicate == null ? termPredicate : predicate.and(termPredicate);
            }
            return predicate;
        },
        customersFiltering(e) {
            const predicate = this.getFilteringQuery(e.text, "fullName");
            let query = new Query();
            if (predicate != null)
                query = query.where(predicate);
            e.updateData(this.customers, query);
        },
        employeesFiltering(e) {
            const predicate = this.getFilteringQuery(e.text, "fullName");
            let query = new Query();
            if (predicate != null)
                query = query.where(predicate);
            e.updateData(this.employees, query);
        },
        async search() {
            const appointments = await this.$store.dispatch("calendar/search", {
                query: this.data.query,
                startDate: this.data.startDate,
                endDate: this.data.endDate != null ? addDays(this.data.endDate, 1) : null,
                offices: this.data.officeIds,
                customers: this.data.customerIds,
                services: this.data.serviceIds,
                employees: this.data.employeeIds
            });
            for (const appointment of appointments) {
                const office = this.offices.find((office) => office.id === appointment.officeId);
                if (office != undefined)
                    appointment.officeName = office.name;
            }
            this.appointmentsResult = {
                data: appointments
            };
        },
        searchSuccess() {
            this.$root.$emit("appointmentSearchResult", this.appointmentsResult);
        },
        cancel() {
            this.appointmentsResult = {
                next: null,
                previous: null,
                data: []
            };
            this.clearFields();
            this.$root.$emit("cancelSearchAppointmentDialog");
        },
        clearFields() {
            for (let i = 1; i <= 2; i++) {
                setTimeout(() => {
                    // Reset data
                    this.data.query = "";
                    this.data.startDate = null;
                    this.data.endDate = null;
                    if (this.hasMultipleOffices)
                        this.data.officeIds = this.officeId != null ? [this.officeId] : [];
                    this.data.customerIds = [];
                    this.data.serviceIds = [];
                    this.data.employeeIds = [];
                }, 200);
            }
        }
    },
    beforeMount() {
        this.$root.$on("officeIdChanged", this.setOfficeId);
        this.$root.$on("findAppointment", this.cancel);
    },
    beforeDestroy() {
        this.$root.$off("officeIdChanged", this.setOfficeId);
        this.$root.$off("findAppointment", this.cancel);
    },
    async mounted() {
        this.offices = await this.$store.dispatch("office/getAll", {
            load: false
        });
        this.offices = this.offices.filter(office => office.id != null);
        this.customers = await this.$store.dispatch("customer/getAll", {
            load: false
        });
        this.customers = this.customers.filter(customer => customer.deletedAt == undefined);
        this.customers.forEach(customer => customer.fullName = `${customer.name} ${customer.surname}`);
        this.services = await this.$store.dispatch("service/getAll", {
            load: false
        });
        this.services = this.services.filter(service => service.deletedAt == undefined);
        this.employees = await this.$store.dispatch("employee/getAll", {
            load: false
        });
        this.employees = this.employees.filter(employee => employee.deletedAt == undefined);
        this.employees.forEach(employee => employee.fullName = `${employee.name} ${employee.surname}`);
        this.clearFields();
        if (!this.hasMultipleOffices)
            this.data.officeIds = [this.getSingleOfficeId];
    }
});
