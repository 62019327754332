import Vue from "vue";
import { Day, DragAndDrop, getRecurrenceStringFromDate, Month, Resize, SchedulePlugin, TimelineViews, Week } from "@syncfusion/ej2-vue-schedule";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import LocaleMixin from "@/mixins/locale";
import DialogMixin, { DialogResult } from "@/mixins/dialog";
import { addDays, formatTime, getDifferenceInMinutes, getFirstDayOfWeek } from "@/helpers/dateTimeHelper";
import { AppointmentsCustomUrlAdaptor } from "@/syncfusion/AppointmentsCustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import resourceHeaderTemplate, { ResourceType } from "@/components/Templates/Calendar/ResourceHeaderTemplate.vue";
import editorTemplateVue from "@/components/Templates/Calendar/EditorTemplate.vue";
import AppointmentTemplate from "@/components/Templates/Calendar/AppointmentTemplate.vue";
import AppointmentSearchToolbar from "@/components/Templates/Calendar/AppointmentSearchToolbar.vue";
import CreateCustomer from "@/components/Templates/Customer/CreateCustomer.vue";
import FirstAvailability from "@/components/Templates/Calendar/FirstAvailability.vue";
import { EmployeeCalendarTypeVisibility } from "@/components/Inputs/AppDropDownListEmployeeCalendarTypeVisibility.vue";
import { mapGetters } from "vuex";
import AppointmentSearchToolbarDialog from "@/components/Templates/Calendar/AppointmentSearchToolbarDialog.vue";
import AppointmentTooltipTemplate from "@/components/Templates/Calendar/AppointmentTooltipTemplate.vue";
import { delay } from "@/helpers/promiseHelper";
Vue.use(SchedulePlugin);
var ResourceGrouping;
(function (ResourceGrouping) {
    ResourceGrouping[ResourceGrouping["None"] = 1] = "None";
    ResourceGrouping[ResourceGrouping["Employees"] = 2] = "Employees";
    ResourceGrouping[ResourceGrouping["Rooms"] = 3] = "Rooms";
    ResourceGrouping[ResourceGrouping["Equipments"] = 4] = "Equipments";
})(ResourceGrouping || (ResourceGrouping = {}));
export default Vue.extend({
    components: {
        "appointment-search-toolbar-dialog": AppointmentSearchToolbarDialog
    },
    mixins: [TitleMixin, SpinnerMixin, LocaleMixin, DialogMixin],
    data() {
        return {
            newCustomerDialogContentTemplate: function () {
                return { template: CreateCustomer };
            },
            firstAvailabilityDialogContentTemplate: function () {
                return { template: FirstAvailability };
            },
            appointmentsSearchDialogContentTemplate: function () {
                return { template: AppointmentSearchToolbar };
            },
            isReady: false,
            appointmentID: null,
            excludeAppointmentID: undefined,
            dragData: {
                appointmentId: 0,
                startTime: null,
                endTime: null,
                employeeId: 0
            },
            enableCloseEdit: true,
            title: this.$t("calendar.menu"),
            views: ["Day", "Week", "Month", "TimelineDay"],
            workDays: [0, 1, 2, 3, 4, 5, 6],
            workHours: {
                highlight: true,
                start: "00:00",
                end: "23:59"
            },
            startTime: null,
            endTime: null,
            calendarHeight: window.innerHeight - 85,
            selectedDate: new Date(),
            firstDayOfWeek: getFirstDayOfWeek(this.appLocale),
            enableRecurrenceValidation: true,
            reopeningApointment: false,
            findingAppointment: false,
            findAppointmentData: null,
            appointmentsBound: false,
            recurrentAppointmentsLoaded: false,
            eventSettings: {
                editFollowingEvents: true,
                //dataSource: this.$store.getters["calendar/getAppointmets"],
                dataSource: new DataManager({
                    url: process.env.VUE_APP_API_BASE_URL + "appointments/grid-data",
                    insertUrl: process.env.VUE_APP_API_BASE_URL + "appointments/grid-create",
                    crudUrl: process.env.VUE_APP_API_BASE_URL + "appointments/grid-create",
                    adaptor: new AppointmentsCustomUrlAdaptor(),
                    crossDomain: true
                }),
                fields: {
                    id: "Id",
                    subject: {
                        name: "Subject"
                    },
                    startTime: {
                        name: "StartTime",
                        validation: { required: true }
                    },
                    endTime: {
                        name: "EndTime",
                        validation: { required: true }
                    }
                },
                template: function () {
                    return {
                        template: AppointmentTemplate
                    };
                },
                enableTooltip: true,
                tooltipTemplate: function () {
                    return {
                        template: AppointmentTooltipTemplate
                    };
                }
            },
            group: { resources: ["Employees"] },
            offices: [],
            employees: [],
            employeesResource: [],
            rooms: [],
            roomsResource: [],
            equipments: [],
            equipmentsResource: [],
            services: [],
            calendarTypes: [],
            resourcesGroupingData: [],
            resourceGroupingId: ResourceGrouping.Employees,
            selectedOfficeId: -1,
            selectedCalendarTypeId: -1,
            selectedEmployeeIds: [],
            personalNotesId: -1,
            previousWindowCoordinates: {
                x: 0,
                y: 0
            },
            enableScrolling: true,
            windowScrollOptions: {
                capture: true,
                passive: false
            },
            isEmployeeSelected: false,
            editorTemplate: function () {
                // Get office drop down list
                const element = document.getElementById("officeId");
                const dropDownList = element.ej2_instances[0];
                return {
                    template: {
                        extends: editorTemplateVue,
                        propsData: { defaultOfficeId: dropDownList.value }
                    }
                };
            },
            resourceHeaderTemplate: function () {
                return { template: resourceHeaderTemplate };
            }
        };
    },
    provide: {
        schedule: [Day, Week, Month, TimelineViews, DragAndDrop, Resize]
    },
    methods: {
        addPersonalNotes() {
            const personalNote = {
                name: this.$t("shared.notes"),
                id: this.personalNotesId,
                price: 0,
                description: "",
                multipleParticipants: false,
                roomRequired: false,
                roomMandatory: false,
                equipmentRequired: false,
                equipmentMandatory: false,
                offices: []
            };
            this.services = [personalNote].concat(this.services);
        },
        onOfficeIdValueChanged() {
            setTimeout(() => {
                const selectedOffice = this.offices.find(office => office.id == this.selectedOfficeId);
                this.startTime = selectedOffice?.openingTime ?? null;
                this.endTime = selectedOffice?.closingTime ?? null;
            }, 0);
            if (!this.findingAppointment) {
                setTimeout(() => {
                    this.selectedCalendarTypeId =
                        this.calendarTypesData.length > 0
                            ? this.calendarTypesData[0].id
                            : -1;
                }, 0);
                if (this.selectedOfficeId)
                    localStorage.setItem("officeId", this.selectedOfficeId.toString());
            }
            this.$root.$emit("officeIdChanged", this.selectedOfficeId);
        },
        onCalendarTypeIdValueChanged() {
            this.setPreselectedEmployeesIds();
            setTimeout(() => {
                this.refreshResources();
                this.$refs.calendar.ej2Instances.refreshEvents();
            }, 100);
        },
        onEmployeeSelectorValueChanged() {
            setTimeout(() => {
                this.refreshResources();
                this.$refs.calendar.ej2Instances.refreshEvents();
            }, 100);
        },
        onResourceGroupingIdValueChanged(value) {
            this.showSpinner();
            setTimeout(() => {
                const calendar = this.$refs.calendar;
                const calendarInstance = calendar.ej2Instances;
                switch (value) {
                    case ResourceGrouping.None:
                        calendarInstance.group.resources = [];
                        break;
                    case ResourceGrouping.Employees:
                        calendarInstance.group.resources = ["Employees"];
                        break;
                    case ResourceGrouping.Rooms:
                        calendarInstance.group.resources = ["Rooms"];
                        break;
                    case ResourceGrouping.Equipments:
                        calendarInstance.group.resources = ["Equipments"];
                        break;
                }
                setTimeout(() => {
                    this.refreshResources();
                    calendarInstance.dataBind();
                }, 100);
                this.hideSpinner();
            }, 100);
        },
        getAvailableEmployees() {
            if (this.selectedOfficeId == null || this.selectedCalendarTypeId == null)
                return [];
            return this.employees.filter(item => item.calendarTypes.some(calendarType => calendarType.calendarTypeId === this.selectedCalendarTypeId));
        },
        setPreselectedEmployeesIds() {
            if (this.isEmployeeSelected)
                return;
            if (this.getEmployeeId != null) {
                setTimeout(() => {
                    this.selectedEmployeeIds = [this.getEmployeeId];
                    this.refreshResources();
                }, 100);
                setTimeout(() => {
                    this.isEmployeeSelected = true;
                }, 5000);
            }
        },
        refreshResources() {
            switch (this.resourceGroupingId) {
                case ResourceGrouping.None:
                    break;
                case ResourceGrouping.Employees:
                    this.refreshEmployeesResources();
                    break;
                case ResourceGrouping.Rooms:
                    this.refreshRoomsResources();
                    break;
                case ResourceGrouping.Equipments:
                    this.refreshEquipmentsResources();
                    break;
            }
        },
        refreshEmployeesResources() {
            if (!this.appointmentsBound) {
                // this.employeesResource = [];
                setTimeout(() => this.refreshEmployeesResources(), 100);
                return;
            }
            const calendar = this.$refs.calendar;
            if (calendar == undefined) {
                return;
            }
            const currentDates = calendar.getCurrentViewDates();
            const currentEvents = calendar.getCurrentViewEvents();
            const employeesWorkHours = [];
            for (const employee of this.employees) {
                if (this.selectedEmployeeIds.length > 0 &&
                    !this.selectedEmployeeIds.includes(employee.id))
                    continue;
                const employeeWorkHours = [];
                for (const currentDate of currentDates) {
                    if (!this.isEmployeeEmployed(employee, currentDate) ||
                        !this.isEmployeeVisible(employee, this.selectedOfficeId, currentEvents, currentDate))
                        continue;
                    const startEndTime = this.getEmployeeStartEndTimes(employee, this.selectedOfficeId, currentDate);
                    employeeWorkHours.push({
                        date: currentDate,
                        startTime: startEndTime.startTime,
                        endTime: startEndTime.endTime
                    });
                }
                if (employeeWorkHours.length > 0)
                    employeesWorkHours.push({
                        employee,
                        employeeWorkHours
                    });
            }
            this.employeesResource = employeesWorkHours.map(employeeWorkHours => {
                return {
                    id: employeeWorkHours.employee.id,
                    type: ResourceType.Employee,
                    name: employeeWorkHours.employee.fullName,
                    image: employeeWorkHours.employee.image,
                    designation: employeeWorkHours.employee.employeeRoleName,
                    startHour: "00:00",
                    endHour: "00:00"
                };
            });
            setTimeout(() => {
                const lastEmployeeId = this.resourceGroupingId == ResourceGrouping.Employees
                    ? localStorage.getItem("employeeId")
                    : undefined;
                if (lastEmployeeId) {
                    for (let iEmployeeWorkHours = 0; iEmployeeWorkHours < employeesWorkHours.length; iEmployeeWorkHours++) {
                        const employeeWorkHours = employeesWorkHours[iEmployeeWorkHours];
                        if (employeeWorkHours.employee.id == +lastEmployeeId) {
                            calendar.selectResourceByIndex(iEmployeeWorkHours);
                            break;
                        }
                    }
                }
                for (let iEmployeeWorkHours = 0; iEmployeeWorkHours < employeesWorkHours.length; iEmployeeWorkHours++) {
                    const employeeWorkHours = employeesWorkHours[iEmployeeWorkHours];
                    for (const employeeWorkHour of employeeWorkHours.employeeWorkHours) {
                        calendar.setWorkHours([employeeWorkHour.date], employeeWorkHour.startTime
                            ? employeeWorkHour.startTime.toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit"
                            })
                            : "00:00", employeeWorkHour.endTime
                            ? employeeWorkHour.endTime.toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit"
                            })
                            : "00:00", iEmployeeWorkHours);
                    }
                }
            }, 100);
        },
        async getEmployeeImage(id) {
            try {
                const imageContent = await this.$store.dispatch("employee/getAvatar", {
                    id: id
                });
                return `data:image/jpeg;base64,${imageContent}`;
            }
            catch {
                return undefined;
            }
        },
        refreshRoomsResources() {
            this.roomsResource = this.rooms
                .filter(room => this.isRoomVisible(room, this.selectedOfficeId))
                .map(room => {
                return {
                    id: room.id,
                    type: ResourceType.Room,
                    name: room.name,
                    designation: this.$t("rooms.room"),
                    startHour: "00:00",
                    endHour: "23:59"
                };
            });
        },
        isRoomVisible(room, officeId) {
            return room.officeId == officeId;
        },
        refreshEquipmentsResources() {
            this.equipmentsResource = this.equipments
                .filter(equipment => this.isEquipmentVisible(equipment, this.selectedOfficeId))
                .map(equipment => {
                return {
                    id: equipment.id,
                    type: ResourceType.Equipment,
                    name: equipment.name,
                    designation: this.$t("equipments.equipment"),
                    startHour: "00:00",
                    endHour: "23:59"
                };
            });
        },
        isEquipmentVisible(equipment, officeId) {
            return equipment.officeId == officeId;
        },
        isEmployeeEmployed(employee, date) {
            if (employee.deletedAt != undefined)
                return false;
            if (employee.employmentDate > date)
                return false;
            if (employee.terminationDate != undefined &&
                employee.terminationDate < date)
                return false;
            return true;
        },
        isEmployeeVisible(employee, officeId, appointments, date) {
            if (this.selectedCalendarTypeId == null)
                return false;
            const employeeCalendarType = employee.calendarTypes.find(employeeCalendarType => employeeCalendarType.calendarTypeId === this.selectedCalendarTypeId);
            if (employeeCalendarType == undefined)
                return false;
            switch (employeeCalendarType.visibility) {
                case EmployeeCalendarTypeVisibility.Never:
                    // Never show the employee
                    return false;
                case EmployeeCalendarTypeVisibility.WhenHaveAppointmentInRoom: {
                    // Check if the employee has an appointment in one of the calendar type rooms
                    const calendarType = this.calendarTypes.find(calendarType => calendarType.id === this.selectedCalendarTypeId);
                    if (calendarType == undefined || calendarType.rooms.length === 0)
                        return false;
                    const employeeAppointments = appointments.filter(appointment => appointment.EmployeeId === employee.id &&
                        appointment.OfficeId === officeId);
                    if (employeeAppointments.length === 0)
                        return false;
                    return employeeAppointments.some(appointment => calendarType.rooms?.some(calendarTypeRoom => calendarTypeRoom.roomId === appointment.RoomId));
                }
                case EmployeeCalendarTypeVisibility.WhenHaveAppointments:
                    // Check if the employee has an appointment
                    return appointments.some(appointment => appointment.EmployeeId === employee.id &&
                        appointment.OfficeId === officeId);
                case EmployeeCalendarTypeVisibility.WhenIsAvailable: {
                    // Check if the employee has an appointment
                    if (appointments.some(appointment => appointment.EmployeeId === employee.id &&
                        appointment.OfficeId === officeId))
                        return true;
                    // Check if the employee is available
                    const employeeWorkHours = this.getEmployeeWorkHours(employee, officeId, date);
                    return employeeWorkHours.length > 0;
                }
                case EmployeeCalendarTypeVisibility.Always:
                    // Always show the employee
                    return true;
                default:
                    throw new Error(`The employee calendar type visibility ${employeeCalendarType.visibility} is not managed`);
            }
        },
        getEmployeeWorkHours(employee, officeId, date) {
            const employeeDailyAvailabilities = employee.dailyWorkAvailabilities?.filter(employeeDailyAvailability => employeeDailyAvailability.officeId == officeId &&
                new Date(employeeDailyAvailability.validFrom) <= date &&
                new Date(employeeDailyAvailability.validTo) >= date) ?? [];
            if (employeeDailyAvailabilities.length > 0) {
                if (employeeDailyAvailabilities.some(employeeDailyAvailability => employeeDailyAvailability.isDayOff))
                    return [];
                return employeeDailyAvailabilities.map(employeeDailyAvailability => {
                    return {
                        startTime: employeeDailyAvailability.startTime,
                        endTime: employeeDailyAvailability.endTime
                    };
                });
            }
            const employeeWorkHours = employee.workHours?.filter(employeeWorkHour => employeeWorkHour.officeId == officeId &&
                (employeeWorkHour.validFrom == null ||
                    employeeWorkHour.validFrom <= date) &&
                (employeeWorkHour.validTo == null ||
                    employeeWorkHour.validTo >= date) &&
                employeeWorkHour.dayOfWeek === date.getDay()) ?? [];
            if (employeeWorkHours.length > 0) {
                return employeeWorkHours.map(employeeWorkHour => {
                    return {
                        startTime: employeeWorkHour.startTime,
                        endTime: employeeWorkHour.endTime
                    };
                });
            }
            return [];
        },
        getEmployeeStartEndTimes(employee, officeId, date) {
            const employeeWorkHours = this.getEmployeeWorkHours(employee, officeId, date);
            if (employeeWorkHours.length === 0)
                return { startTime: undefined, endTime: undefined };
            let startTime = employeeWorkHours[0].startTime;
            let endTime = employeeWorkHours[0].endTime;
            for (const employeeWorkHour of employeeWorkHours.slice(1)) {
                if (employeeWorkHour.startTime < startTime)
                    startTime = employeeWorkHour.startTime;
                if (employeeWorkHour.endTime > endTime)
                    endTime = employeeWorkHour.endTime;
            }
            return { startTime, endTime };
        },
        scrollWindowToTopAndDisableScrolling() {
            const calendar = this.$refs.calendar;
            const schedule = calendar.ej2Instances;
            if (schedule.isAdaptive) {
                this.previousWindowCoordinates = {
                    x: window.scrollX,
                    y: window.scrollY
                };
                window.scrollTo(0, 0);
                this.enableScrolling = false;
            }
        },
        scrollWindowToPreviousPositionAndEnableScrolling() {
            const calendar = this.$refs.calendar;
            const schedule = calendar.ej2Instances;
            if (schedule.isAdaptive) {
                this.enableScrolling = true;
                window.scrollTo(this.previousWindowCoordinates.x, this.previousWindowCoordinates.y);
            }
        },
        dataBinding(args) {
            for (const appointment of args.result) {
                if (appointment.RecurrenceExceptionDates != null) {
                    appointment.RecurrenceException = [
                        appointment.RecurrenceException,
                        appointment.RecurrenceExceptionDates
                    ]
                        .filter(Boolean)
                        .join(",");
                    appointment.RecurrenceException = [
                        ...new Set(appointment.RecurrenceException.split(","))
                    ].join(",");
                }
                if (appointment.ServiceId == null)
                    appointment.ServiceId = this.personalNotesId;
                appointment.CustomerIds = appointment.Customers?.map((customer) => {
                    return customer.CustomerId;
                });
                appointment.CustomersNotes = appointment.Customers?.map((customer) => {
                    return `${customer.CustomerId}_${customer.CustomerNotes}`;
                });
                appointment.CustomersCancelType = appointment.Customers?.map((customer) => {
                    return `${customer.CustomerId}_${customer.CustomerCancelType}`;
                });
                appointment.EmployeeName = this.employees.find(employee => appointment.EmployeeId == employee.id)?.fullName;
                appointment.ServiceName = this.services.find(service => appointment.ServiceId == service.id)?.name;
                if (appointment.RoomId != null)
                    appointment.RoomName = this.rooms.find(room => appointment.RoomId == room.id)?.name;
                if (appointment.EquipmentId != null)
                    appointment.EquipmentName = this.equipments.find(equipment => appointment.EquipmentId == equipment.id)?.name;
            }
        },
        dataBound() {
            this.appointmentsBound = true;
            if (!this.findingAppointment)
                return;
            setTimeout(() => {
                if (!this.findingAppointment)
                    return;
                this.findingAppointment = false;
                const calendar = this.$refs.calendar;
                calendar.scrollTo(formatTime(this.appLocale, this.selectedDate));
                if (this.findAppointmentData != null &&
                    this.findAppointmentData.id > 0) {
                    const event = calendar.getEvents().find(appointment => appointment.Id == this.findAppointmentData.id);
                    if (event.ParentId == undefined || event.RecurrenceID != undefined)
                        calendar.openEditor(event, "Save", true);
                    else {
                        const parentEvent = calendar.getCurrentViewEvents().find(appointment => appointment.Id == event.ParentId);
                        calendar.openEditor(parentEvent, "EditOccurrence", true);
                    }
                }
                else {
                    const event = {
                        StartTime: this.findAppointmentData.startTime,
                        EndTime: this.findAppointmentData.endTime,
                        OfficeId: this.findAppointmentData.officeId,
                        ServiceId: this.findAppointmentData.serviceId,
                        EmployeeId: this.findAppointmentData.employeeId,
                        EquipmentId: this.findAppointmentData.equipmentId,
                        RoomId: this.findAppointmentData.roomId
                    };
                    calendar.openEditor(event, "Add", true);
                }
                this.findAppointmentData = null;
            }, 100);
        },
        eventRendered(args) {
            // Workaround to hide appointments from different office
            // TODO: Find better solution
            if (args.data.OfficeId !== this.selectedOfficeId) {
                args.cancel = true;
                return;
            }
            // Hide recurrent event to avoid duplicates (Schedule component automatically creates recurrent events from parent)
            if (args.data.ParentId != null && args.data.RecurrenceID == null) {
                args.cancel = true;
                return;
            }
            // Exclude events from not selected employees
            if (this.selectedEmployeeIds.length > 0 &&
                !this.selectedEmployeeIds.includes(args.data.EmployeeId)) {
                args.cancel = true;
                return;
            }
            if (args.data.IsCanceled)
                args.element.style.opacity = 0.4;
            // Check if the employee has an appointment in one of the calendar type rooms
            const employee = this.employees.find(employee => employee.id == args.data.EmployeeId);
            const calendarType = this.calendarTypes.find(calendarType => calendarType.id === this.selectedCalendarTypeId);
            if (employee !== undefined && calendarType !== undefined) {
                const employeeCalendarType = employee.calendarTypes.find(employeeCalendarType => employeeCalendarType.calendarTypeId === this.selectedCalendarTypeId);
                if (employeeCalendarType !== undefined &&
                    employeeCalendarType.visibility ===
                        EmployeeCalendarTypeVisibility.WhenHaveAppointmentInRoom) {
                    if (!calendarType.rooms?.some(calendarTypeRoom => calendarTypeRoom.roomId === args.data.RoomId)) {
                        args.element.style.opacity = 0.12;
                    }
                }
            }
            // Set child id
            if (args.data.RecurrenceRule != null && args.data.RecurrenceRule != "") {
                const calendar = this.$refs.calendar;
                const shownEvents = calendar.getCurrentViewEvents();
                const allEvents = calendar.getEvents();
                const childAppointment = allEvents.find(event => event.ParentId === args.data.Id &&
                    event.StartTime.getTime() === args.data.StartTime.getTime() &&
                    event.EndTime.getTime() === args.data.EndTime.getTime());
                const currentAppointment = shownEvents.find(event => event.Guid === args.data.Guid);
                currentAppointment.ChildId = childAppointment.Id;
                currentAppointment.PaymentStatus = childAppointment.PaymentStatus;
                currentAppointment.PaidCustomers = childAppointment.PaidCustomers;
            }
            // Set event background color
            if (employee != undefined)
                args.element.style.backgroundColor = employee.color;
            const duration = getDifferenceInMinutes(args.data.EndTime, args.data.StartTime);
            if (duration < 30) {
                const headerElement = args.element.querySelector(".appointment-header");
                headerElement.style.paddingTop = 0;
                const headerSubjectElement = headerElement.querySelector(".e-subject");
                headerSubjectElement.style.fontSize = "11px";
                const headerChipElements = headerElement.querySelectorAll(".e-chip");
                for (const headerChipElement of headerChipElements)
                    headerChipElement.style.height = "15px";
            }
        },
        actionBegin(args) {
            if (["dateNavigate", "viewNavigate"].includes(args.requestType)) {
                this.appointmentsBound = false;
                setTimeout(() => this.refreshResources(), 100);
            }
            if (args.requestType === "resourceChange") {
                if (this.resourceGroupingId == ResourceGrouping.Employees) {
                    if (args.groupIndex < this.employeesResource.length) {
                        const employeeResource = this.employeesResource[args.groupIndex];
                        localStorage.setItem("employeeId", employeeResource.id.toString());
                    }
                }
            }
        },
        actionFailure(args) {
            const error = Array.isArray(args.error)
                ? args.error[0].error
                : args.error.error;
            if (error != undefined) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                    error: error.responseText
                }));
            }
        },
        onDragStart(args) {
            const calendar = this.$refs.calendar;
            if (calendar.ej2Instances.isAdaptive) {
                // Disable drag-and-drop on mobile devices
                args.cancel = true;
                return;
            }
            const appointmentData = args.data;
            this.appointmentID = appointmentData.Id;
            this.excludeAppointmentID = appointmentData.Id;
            if (appointmentData.Id != undefined &&
                appointmentData.Id != "" &&
                appointmentData.Id === +appointmentData.RecurrenceID) {
                // Recurrence override
                appointmentData.RecurrenceRule = "";
                appointmentData.RecurrenceException = "";
                // Get appointment id to exclude
                this.excludeAppointmentID = this.getAppointmentIdToExclude(appointmentData);
            }
            // Reset error status
            this.employeeAvailable = true;
            this.employeeErrorMessage = "";
            this.roomAvailable = true;
            this.roomErrorMessage = "";
            this.equipmentAvailable = true;
            this.equipmentErrorMessage = "";
            this.dragData = {
                appointmentId: appointmentData.Id,
                startTime: appointmentData.StartTime,
                endTime: appointmentData.EndTime,
                employeeId: appointmentData.EmployeeId
            };
        },
        async onDragStop(args) {
            const calendar = this.$refs.calendar;
            if (calendar.ej2Instances.isAdaptive) {
                // Disable drag-and-drop on mobile devices
                args.cancel = true;
                return;
            }
            const appointmentData = args.data;
            args.cancel = true;
            // Check if data is changed
            if (appointmentData.StartTime.getTime() ==
                this.dragData.startTime?.getTime() &&
                appointmentData.EndTime.getTime() == this.dragData.endTime?.getTime() &&
                appointmentData.EmployeeId == this.dragData.employeeId)
                return;
            // Check if new employee manages the same service
            if (appointmentData.EmployeeId != this.dragData.employeeId) {
                const employee = this.employees.find((employee) => employee.id == appointmentData.EmployeeId);
                if (employee == undefined) {
                    this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("calendar.moveAppointment.errorWhileMovingAppointmentOnEmployee"));
                    return;
                }
                if (employee.services.find((employeeService) => appointmentData.ServiceId == employeeService.serviceId) == undefined) {
                    const service = this.services.find((service) => appointmentData.ServiceId == service.id);
                    const serviceName = service?.name || "sconosciuto";
                    this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("calendar.moveAppointment.employeeDoesntManageThisService", {
                        employee: employee.fullName,
                        service: serviceName
                    }));
                    return;
                }
            }
            calendar.showSpinner();
            try {
                // Recurrence override
                appointmentData.RecurrenceRule = "";
                appointmentData.RecurrenceException = "";
                // Load recurrent appointments
                if (appointmentData.RecurrenceID || appointmentData.RecurrenceRule) {
                    await this.loadRecurrentEvents(appointmentData.Id);
                    await delay(200);
                }
                // Save appointment
                if (!(await this.canSaveAppointment(appointmentData, true))) {
                    return;
                }
                this.saveAppointment(calendar, appointmentData);
            }
            catch (error) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), error);
            }
            finally {
                calendar.hideSpinner();
            }
        },
        onResizeStart(args) {
            const calendar = this.$refs.calendar;
            if (calendar.ej2Instances.isAdaptive) {
                // Disable resize on mobile devices
                args.cancel = true;
                return;
            }
            const appointmentData = args.data;
            this.appointmentID = appointmentData.Id;
            this.excludeAppointmentID = appointmentData.Id;
            if (appointmentData.Id != undefined &&
                appointmentData.Id !== "" &&
                appointmentData.Id === +appointmentData.RecurrenceID) {
                // Recurrence override
                appointmentData.RecurrenceRule = "";
                appointmentData.RecurrenceException = "";
                // Get appointment id to exclude
                this.excludeAppointmentID = this.getAppointmentIdToExclude(appointmentData);
            }
            // Reset error status
            this.employeeAvailable = true;
            this.employeeErrorMessage = "";
            this.roomAvailable = true;
            this.roomErrorMessage = "";
            this.equipmentAvailable = true;
            this.equipmentErrorMessage = "";
            this.dragData = {
                appointmentId: appointmentData.Id,
                startTime: appointmentData.StartTime,
                endTime: appointmentData.EndTime,
                employeeId: appointmentData.EmployeeId
            };
        },
        async onResizeStop(args) {
            const calendar = this.$refs.calendar;
            if (calendar.ej2Instances.isAdaptive) {
                // Disable resize on mobile devices
                args.cancel = true;
                return;
            }
            const appointmentData = args.data;
            args.cancel = true;
            // Check if data is changed
            if (appointmentData.StartTime.getTime() ==
                this.dragData.startTime?.getTime() &&
                appointmentData.EndTime.getTime() == this.dragData.endTime?.getTime())
                return;
            calendar.showSpinner();
            try {
                // Load recurrent appointments
                if (appointmentData.RecurrenceID || appointmentData.RecurrenceRule) {
                    await this.loadRecurrentEvents(appointmentData.Id);
                    await delay(200);
                }
                // Recurrence override
                appointmentData.RecurrenceRule = "";
                appointmentData.RecurrenceException = "";
                // Save appointment
                if (!(await this.canSaveAppointment(appointmentData, true))) {
                    return;
                }
                this.saveAppointment(calendar, appointmentData);
            }
            catch (error) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), error);
            }
            finally {
                calendar.hideSpinner();
            }
        },
        async onPopupOpen(args) {
            if (args.type === "Editor") {
                // Load missing recurrences of the appointment that need to be open
                if (!this.recurrentAppointmentsLoaded && (args.data.RecurrenceID || args.data.RecurrenceRule)) {
                    args.cancel = true;
                    const calendar = this.$refs.calendar;
                    const schedule = calendar.ej2Instances;
                    calendar.showSpinner();
                    try {
                        await this.loadRecurrentEvents(args.data.Id);
                        this.recurrentAppointmentsLoaded = true;
                        await delay(200);
                        calendar.openEditor(args.data, schedule.currentAction, true);
                    }
                    catch (errors) {
                        this.recurrentAppointmentsLoaded = true;
                        this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                            error: errors[0].message
                        }));
                    }
                    finally {
                        calendar.hideSpinner();
                    }
                    return;
                }
                this.recurrentAppointmentsLoaded = false;
                const calendar = this.$refs.calendar;
                const schedule = calendar.ej2Instances;
                let appointmentData = args.data;
                // Update recurrence rule
                if (!this.reopeningApointment &&
                    schedule.currentAction === "EditFollowingEvents") {
                    if (appointmentData.Id !== appointmentData.RecurrenceID) {
                        appointmentData = Object.assign({}, appointmentData);
                        appointmentData.ChildId = appointmentData.Id;
                        appointmentData.Id = appointmentData.RecurrenceID;
                        appointmentData.RecurrenceException = null;
                        appointmentData.RecurrenceExceptionDates = null;
                    }
                    this.enableRecurrenceValidation = false;
                    schedule.uiStateValues.isIgnoreOccurrence = true;
                    const currentEvents = schedule.eventsData;
                    const parentEvent = currentEvents.find(event => event.Id === appointmentData.RecurrenceID);
                    if (parentEvent.RecurrenceRule.match(/COUNT=/i)) {
                        // Get following events and calculate recurrence rule
                        const followingEvents = currentEvents.filter(event => event.Id !== +appointmentData.RecurrenceID &&
                            event.ParentId === +appointmentData.RecurrenceID &&
                            event.StartTime.getTime() >= appointmentData.StartTime.getTime());
                        appointmentData.RecurrenceRule = parentEvent.RecurrenceRule.replace(/COUNT=\d+/i, `COUNT=${followingEvents.length}`);
                        // Reopen the editor (to reflect the changes)
                        args.cancel = true;
                        this.reopeningApointment = true;
                        setTimeout(() => calendar.openEditor(appointmentData, schedule.currentAction, true), 200);
                        return;
                    }
                }
                this.reopeningApointment = false;
                this.appointmentID = appointmentData.Id;
                this.excludeAppointmentID = appointmentData.Id;
                if (appointmentData.Id != undefined &&
                    appointmentData.Id !== "" &&
                    appointmentData.Id === +appointmentData.RecurrenceID) {
                    // Get appointment id to exclude
                    this.excludeAppointmentID = this.getAppointmentIdToExclude(appointmentData);
                }
                this.enableCloseEdit = false;
                // Reset error status
                this.employeeAvailable = true;
                this.employeeErrorMessage = "";
                this.roomAvailable = true;
                this.roomErrorMessage = "";
                this.equipmentAvailable = true;
                this.equipmentErrorMessage = "";
                const formElement = args.element.querySelector(".e-schedule-form");
                this.addAppointmentFormValidationRules(formElement);
                this.scrollWindowToTopAndDisableScrolling();
                setTimeout(() => {
                    args.element.querySelector(".e-dlg-content").scrollTop = 0;
                }, 0);
            }
        },
        async popupClose(args) {
            const calendar = this.$refs.calendar;
            const schedule = calendar.ej2Instances;
            if (!schedule.eventWindow.isCrudAction) {
                if (args.type === "Editor")
                    this.scrollWindowToPreviousPositionAndEnableScrolling();
                return true;
            }
            const appointmentData = args.data;
            if (appointmentData == null) {
                if (args.type === "Editor")
                    this.scrollWindowToPreviousPositionAndEnableScrolling();
                return false;
            }
            if (this.enableCloseEdit)
                return true;
            args.cancel = true;
            // Update subject
            const previousSubject = args.data.Subject;
            if (args.data.ServiceId != this.personalNotesId)
                args.data.Subject = null;
            // Update recurrence rule on following events edit
            this.updateRecurrenceRuleOnFollowingEventsEdit(schedule, appointmentData);
            // Update customers notes and cancel type
            const customersNotes = args.data.CustomersNotes.map((customerNotes) => {
                const indexOfUnderscore = customerNotes.indexOf("_");
                return {
                    id: +customerNotes.slice(0, indexOfUnderscore),
                    notes: customerNotes.slice(indexOfUnderscore + 1)
                };
            });
            const customersCancelType = args.data.CustomersCancelType.map((customerCancelType) => {
                const indexOfUnderscore = customerCancelType.indexOf("_");
                return {
                    id: +customerCancelType.slice(0, indexOfUnderscore),
                    cancelType: +customerCancelType.slice(indexOfUnderscore + 1)
                };
            });
            args.data.Customers = [];
            for (const customerId of args.data.CustomerIds ?? []) {
                args.data.Customers.push({
                    customerId: customerId,
                    customerNotes: customersNotes.find(customerNotes => customerNotes.id === customerId)?.notes ?? "",
                    customerCancelType: customersCancelType.find(customerCancelType => customerCancelType.id === customerId)?.cancelType ?? 0
                });
            }
            // Reset recurrence exceptions if we are regenerating all recurrences
            if (appointmentData.RecurrenceRule != undefined &&
                appointmentData.RecurrenceRule != "" &&
                (!schedule.uiStateValues.isIgnoreOccurrence ||
                    schedule.currentAction === "EditFollowingEvents")) {
                appointmentData.RecurrenceException = null;
                appointmentData.RecurrenceExceptionDates = null;
            }
            try {
                // Check if the appointment can be saved
                if (!(await this.canSaveAppointment(appointmentData, false))) {
                    args.data.Subject = previousSubject;
                    return;
                }
                // Save appointment
                this.enableCloseEdit = true;
                this.saveAppointment(calendar, appointmentData);
                this.enableRecurrenceValidation = true;
                schedule.uiStateValues.isIgnoreOccurrence = false;
                this.$refs.calendar.closeEditor();
                this.scrollWindowToPreviousPositionAndEnableScrolling();
            }
            catch (error) {
                console.log(error);
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), error);
                args.data.Subject = previousSubject;
            }
        },
        async loadRecurrentEvents(id) {
            console.log("Loading recurrent events...");
            const calendar = this.$refs.calendar;
            const schedule = calendar.ej2Instances;
            const childAppointments = await this.$store.dispatch("calendar/getRecurrences", { id: id });
            const existingAppointmentsIds = schedule.eventsData.map((appointment) => appointment.Id);
            schedule.eventsData = [
                ...schedule.eventsData,
                ...childAppointments.filter((appointment) => !existingAppointmentsIds.includes(appointment.Id))
            ];
            const existingProcessedAppointmentsIds = schedule.eventsProcessed.map((appointment) => appointment.Id);
            schedule.eventsProcessed = [
                ...schedule.eventsProcessed,
                ...childAppointments.filter((appointment) => !existingProcessedAppointmentsIds.includes(appointment.Id))
            ];
        },
        addAppointmentFormValidationRules(formElement) {
            const validator = formElement.ej2_instances[0];
            validator.locale = this.appLocale;
            /*
            validator.addEventListener("validationComplete", (arg: any) => {
              if(arg.status != "success"){
                // failed validation
                arg.element.parentNode.querySelector(".e-label-top").scrollIntoView();
              }
            });
            */
            validator.addRules("Subject", {
                required: [
                    (args) => {
                        const serviceId = args.element.form.querySelector("#ServiceId")
                            .ej2_instances[0].value, subject = args.element.form.querySelector("#Subject")
                            .ej2_instances[0].value;
                        return !(serviceId !== null &&
                            serviceId == this.personalNotesId &&
                            subject.length == 0);
                    }
                ]
            });
            validator.addRules("CustomerId", {
                required: [
                    (args) => {
                        const serviceId = args.element.form.querySelector("#ServiceId")
                            .ej2_instances[0].value;
                        const service = this.services.find((s) => s.id == serviceId);
                        if (serviceId == this.personalNotesId)
                            return true;
                        if (service == undefined)
                            return true;
                        if (service.multipleParticipants)
                            return true;
                        return !isNaN(args.value) && args.value > 0;
                    }
                ]
            });
            validator.addRules("CustomerIds", {
                required: [
                    (args) => {
                        const serviceId = args.element.form.querySelector("#ServiceId")
                            .ej2_instances[0].value;
                        const service = this.services.find((s) => s.id == serviceId);
                        if (service == undefined)
                            return true;
                        if (!service.multipleParticipants)
                            return true;
                        return args.value.length > 0;
                    }
                ]
            });
            validator.addRules("OfficeId", {
                required: true,
                number: true
            });
            validator.addRules("ServiceId", {
                required: true,
                number: true
            });
            validator.addRules("EmployeeId", {
                required: true,
                number: true
            });
            validator.addRules("IsTrial", { required: true });
            validator.addRules("RoomId", {
                required: [
                    (args) => {
                        const serviceId = args.element.form.querySelector("#ServiceId")
                            .ej2_instances[0].value;
                        const service = this.services.find((s) => s.id == serviceId);
                        if (service == undefined)
                            return true;
                        return !service.roomMandatory || !isNaN(parseInt(args.value));
                    }
                ]
            });
            validator.addRules("EquipmentId", {
                required: [
                    (args) => {
                        const serviceId = args.element.form.querySelector("#ServiceId")
                            .ej2_instances[0].value;
                        const service = this.services.find((s) => s.id == serviceId);
                        if (service == undefined)
                            return true;
                        return !service.equipmentMandatory || !isNaN(parseInt(args.value));
                    }
                ]
            });
            validator.addRules("StartTime", {
                required: true,
                date: [
                    (args) => {
                        const dateTime = args.element.form.querySelector("#StartTime")
                            .ej2_instances[0].value;
                        return dateTime != null;
                    }
                ]
            });
            validator.addRules("EndTime", {
                required: true,
                date: [
                    (args) => {
                        const dateTime = args.element.form.querySelector("#EndTime")
                            .ej2_instances[0].value;
                        return dateTime != null;
                    }
                ],
                dateAfter: [
                    (args) => {
                        const startTime = args.element.form.querySelector("#StartTime")
                            .ej2_instances[0].value;
                        const endTime = args.element.form.querySelector("#EndTime")
                            .ej2_instances[0].value;
                        return startTime < endTime;
                    },
                    this.$t("calendar.endDateTimeMustBeGreaterThanStartDate")
                ]
            });
        },
        getAppointmentIdToExclude(appointmentData) {
            const startTime = new Date(appointmentData.StartTime);
            startTime.setHours(0, 0, 0, 0);
            const endTime = new Date(appointmentData.EndTime);
            endTime.setHours(23, 59, 59, 999);
            const schedule = this.$refs.calendar.ej2Instances;
            const events = schedule.eventsData.filter((event) => event.ParentId == appointmentData.RecurrenceID &&
                event.RecurrenceID == null &&
                event.StartTime >= startTime &&
                event.EndTime <= endTime);
            if (events.length !== 1)
                return undefined;
            return events[0].Id;
        },
        getRecurrenceExceptions(appointmentData) {
            const recurrenceExceptions = [
                appointmentData.RecurrenceException,
                appointmentData.RecurrenceExceptionDates
            ];
            if (recurrenceExceptions.length === 0)
                return undefined;
            return recurrenceExceptions.filter(Boolean).join(",");
        },
        async canSaveAppointment(data, showErrorToast) {
            let recurrenceExceptionDates = data.RecurrenceExceptionDates != null
                ? [...new Set(data.RecurrenceExceptionDates.split(","))].join(",")
                : null, service = null;
            if (data.EndTime <= data.StartTime) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("L'orario di fine appuntamento deve essere successivo all'ora di inizio."));
                return false;
            }
            const isPersonalNotes = data.ServiceId == this.personalNotesId;
            if (!isPersonalNotes) {
                service = await this.$store.dispatch("service/get", {
                    id: data.ServiceId
                });
            }
            this.employeeAvailable = await this.isEmployeeAvailable(data.EmployeeId, data.OfficeId, data.StartTime, data.EndTime, this.excludeAppointmentID, data.RecurrenceRule, this.getRecurrenceExceptions(data));
            if (!this.employeeAvailable) {
                this.employeeErrorMessage = this.$t("Il professionista non è disponibile nell'orario selezionato.");
                if (showErrorToast) {
                    this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("Il professionista non è disponibile nell'orario selezionato."));
                }
                return false;
            }
            this.employeeAvailable = await this.isEmployeeWorkHours(data.EmployeeId, data.OfficeId, data.StartTime, data.EndTime, data.RecurrenceRule, this.getRecurrenceExceptions(data));
            if (!this.employeeAvailable) {
                this.employeeErrorMessage = this.$t("L'orario è fuori dall'orario di lavoro del professionista.");
                if (showErrorToast) {
                    this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("L'appuntamento è fuori dall'orario di lavoro del professionista."));
                }
                return false;
            }
            if (!isPersonalNotes && service.roomRequired && data.RoomId !== null) {
                const customersNumber = service.multipleParticipants
                    ? data.Customers.filter(customer => customer.customerCancelType == 0).length
                    : 1;
                const { isAvailable, errors } = await this.isRoomAvailable(data.RoomId, data.StartTime, data.EndTime, customersNumber, this.excludeAppointmentID, data.RecurrenceRule, this.getRecurrenceExceptions(data));
                this.roomAvailable = isAvailable;
                if (!this.roomAvailable &&
                    data.RecurrenceRule != null &&
                    data.RecurrenceRule != "") {
                    const errorMessage = this.getErrorMessage(errors);
                    const dialogResult = await this.showConfirmDialog("", `${errorMessage}<br/>${this.$t("calendar.excludedDates")} ${this.$t("calendar.areYouSure")}`);
                    this.roomAvailable = dialogResult === DialogResult.Yes;
                    if (this.roomAvailable)
                        recurrenceExceptionDates = this.getRecurrenceExceptionDates(recurrenceExceptionDates ?? "", errors);
                }
                if (!this.roomAvailable) {
                    this.roomErrorMessage = this.$t("La stanza non è disponibile nell'orario selezionato.");
                    if (showErrorToast) {
                        //this.roomErrorMessage = errors[0].message;
                        this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("La stanza non è disponibile nell'orario selezionato."));
                    }
                    return false;
                }
            }
            if (!isPersonalNotes &&
                service.equipmentRequired &&
                data.EquipmentId !== null) {
                const { isAvailable, errors } = await this.isEquipmentAvailable(data.EquipmentId, data.StartTime, data.EndTime, this.excludeAppointmentID, data.RecurrenceRule, this.getRecurrenceExceptions(data));
                this.equipmentAvailable = isAvailable;
                if (!this.equipmentAvailable &&
                    data.RecurrenceRule != null &&
                    data.RecurrenceRule != "") {
                    const errorMessage = this.getErrorMessage(errors);
                    const dialogResult = await this.showConfirmDialog("", `${errorMessage}<br/>${this.$t("calendar.excludedDates")} ${this.$t("calendar.areYouSure")}`);
                    this.equipmentAvailable = dialogResult === DialogResult.Yes;
                    if (this.equipmentAvailable)
                        recurrenceExceptionDates = this.getRecurrenceExceptionDates(recurrenceExceptionDates ?? "", errors);
                }
                if (!this.equipmentAvailable) {
                    this.equipmentErrorMessage = this.$t("Lo strumento non è disponibile nell'orario selezionato.");
                    if (showErrorToast) {
                        this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("Lo strumento non è disponibile nell'orario selezionato."));
                    }
                    return false;
                }
            }
            data.RecurrenceExceptionDates = recurrenceExceptionDates;
            return true;
        },
        async isEmployeeAvailable(employeeId, officeId, startDatetime, endDatetime, appointmentIdToExclude, recurrenceRule, recurrenceException) {
            try {
                await this.$store.dispatch("employee/isAvailable", {
                    id: employeeId,
                    officeId: officeId,
                    startDateTime: startDatetime,
                    endDateTime: endDatetime,
                    appointmentId: appointmentIdToExclude,
                    recurrenceRule: recurrenceRule,
                    recurrenceException: recurrenceException
                });
                return true;
            }
            catch (errors) {
                const errorMessage = this.getErrorMessage(errors);
                const dialogResult = await this.showConfirmDialog({
                    title: "",
                    content: `${errorMessage}<br/>${this.$t("calendar.areYouSure")}`
                });
                return dialogResult === DialogResult.Yes;
            }
        },
        async isEmployeeWorkHours(employeeId, officeId, startDateTime, endDateTime, recurrenceRule, recurrenceException) {
            try {
                await this.$store.dispatch("employee/isWorkingHours", {
                    id: employeeId,
                    officeId: officeId,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    recurrenceRule: recurrenceRule,
                    recurrenceException: recurrenceException
                });
                return true;
            }
            catch (errors) {
                const errorMessage = this.getErrorMessage(errors);
                const dialogResult = await this.showConfirmDialog({
                    title: "",
                    content: `${errorMessage}<br/>${this.$t("calendar.areYouSure")}`
                });
                return dialogResult === DialogResult.Yes;
            }
        },
        async isRoomAvailable(roomId, startDateTime, endDateTime, customersNumber, appointmentIdToExclude, recurrenceRule, recurrenceException) {
            try {
                await this.$store.dispatch("room/isAvailable", {
                    id: roomId,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    customersNumber: customersNumber,
                    appointmentId: appointmentIdToExclude,
                    recurrenceRule: recurrenceRule,
                    recurrenceException: recurrenceException
                });
                return { isAvailable: true, errors: undefined };
            }
            catch (errors) {
                return { isAvailable: false, errors: errors };
            }
        },
        async isEquipmentAvailable(equipmentId, startDateTime, endDateTime, appointmentIdToExclude, recurrenceRule, recurrenceException) {
            try {
                await this.$store.dispatch("equipment/isAvailable", {
                    id: equipmentId,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    appointmentId: appointmentIdToExclude,
                    recurrenceRule: recurrenceRule,
                    recurrenceException: recurrenceException
                });
                return { isAvailable: true, errors: undefined };
            }
            catch (errors) {
                return { isAvailable: false, errors: errors };
            }
        },
        updateRecurrenceRuleOnFollowingEventsEdit(schedule, data) {
            // Update RecurrenceRule only on EditFollowingEvents
            if (schedule.currentAction !== "EditFollowingEvents" ||
                !data.RecurrenceRule.match(/COUNT=/i))
                return;
            // Update parent recurrence rule
            const untilDateTime = addDays(data.OriginalStartTime, -1);
            const parentEvent = schedule.eventsData.find((event) => event.Id == data.RecurrenceID);
            parentEvent.RecurrenceRuleOverride = parentEvent.RecurrenceRule.replace(/(COUNT=\d+)|(UNTIL=\d{4}\d{2}\d{2}T\d{2}\d{2}\d{2}Z)/i, `UNTIL=${getRecurrenceStringFromDate(untilDateTime)}`);
            // Set updated appointment recurrence rule
            data.RecurrenceRuleOverride = data.RecurrenceRule;
        },
        saveAppointment(calendar, data) {
            const currentAction = calendar.ej2Instances.currentAction;
            if (data.ServiceId == this.personalNotesId)
                data.ServiceId = null;
            // Create new appointment
            if (currentAction === "Add") {
                calendar.addEvent(data);
                return;
            }
            // Convert recurrence id string into number
            if (data.RecurrenceID != undefined && data.RecurrenceID != "")
                data.RecurrenceID = +data.RecurrenceID;
            // Get save action and remove id on recurrence appointment update
            let saveAction = currentAction;
            if (this.appointmentID === data.RecurrenceID) {
                this.appointmentID = null;
                // Set edit recurrence action (for resize and drag & drop)
                if (saveAction == null)
                    saveAction = "EditOccurrence";
            }
            // Fix appointment id
            data.Id = this.appointmentID;
            // Save appointment
            if (data.Id != null && currentAction !== "EditSeries") {
                calendar.saveEvent(data);
                return;
            }
            // Save appointment with specified action
            calendar.saveEvent(data, saveAction);
        },
        getErrorMessage(errors) {
            return errors.map(error => error.message).join("<br/>");
        },
        getRecurrenceExceptionDates(recurrenceExceptionDates, errors) {
            const recurrenceExceptionDatesToAdd = errors
                .filter(error => error.field != null && error.field != "")
                .map(error => getRecurrenceStringFromDate(new Date(error.field)));
            return [
                ...new Set([
                    ...recurrenceExceptionDates.split(","),
                    ...recurrenceExceptionDatesToAdd
                ])
            ].join(",");
        },
        findAppointment(appointment) {
            this.findingAppointment = true;
            this.findAppointmentData = appointment;
            this.selectedDate = appointment.startTime;
            // Close dialogs
            this.closeFirstAvailabilityDialog();
            this.closeAppointmentSearchDialog();
            // Set office id and calendar type
            let calendarType = undefined;
            if (this.selectedOfficeId !== appointment.officeId) {
                this.selectedOfficeId = appointment.officeId;
                if (this.calendarTypesData.length > 0)
                    calendarType = this.calendarTypesData[0];
            }
            const employee = this.employees.find(employee => employee.id === appointment.employeeId);
            if (employee != undefined) {
                let calendarTypes = [];
                for (const visibility of [
                    EmployeeCalendarTypeVisibility.Always,
                    EmployeeCalendarTypeVisibility.WhenIsAvailable
                ]) {
                    calendarTypes = this.calendarTypes.filter(calendarType => calendarType.officeId === this.selectedOfficeId &&
                        employee.calendarTypes?.some(employeeCalendarType => calendarType.id === employeeCalendarType.calendarTypeId &&
                            employeeCalendarType.visibility === visibility));
                    if (calendarTypes.length > 0)
                        break;
                }
                if (calendarTypes.length > 0 &&
                    !calendarTypes.some(calendarType => calendarType.id === this.selectedCalendarTypeId)) {
                    // TODO: Find one with the highest visibility
                    calendarType = calendarTypes[0];
                }
            }
            if (calendarType != undefined) {
                setTimeout(() => {
                    this.selectedCalendarTypeId = calendarType.id;
                }, 0);
            }
            if (employee != undefined &&
                this.selectedEmployeeIds.length > 0 &&
                !this.selectedEmployeeIds.includes(employee.id)) {
                setTimeout(() => {
                    this.selectedEmployeeIds = [
                        ...this.selectedEmployeeIds,
                        employee.id
                    ];
                }, 0);
            }
        },
        hideNewCustomerDialog() {
            this.$refs.newCustomerDialog.hide();
        },
        openNewCustomerDialog() {
            this.$refs.newCustomerDialog.show();
        },
        openFirstAvailabilityDialog() {
            this.$refs.firstAvailabilityDialog.show();
        },
        closeFirstAvailabilityDialog() {
            this.$refs.firstAvailabilityDialog.hide();
        },
        openAppointmentSearchDialog() {
            this.$refs.appointmentSearchDialog.show();
        },
        closeAppointmentSearchDialog() {
            this.$refs.appointmentSearchDialog.hide();
        },
        openAppointmentSearchResultDialog() {
            this.$refs.appointmentSearchResultDialog.show();
        },
        onWindowResize() {
            setTimeout(() => {
                this.calendarHeight = window.innerHeight - 85;
            }, 0);
        },
        onWindowScroll(args) {
            if (!this.enableScrolling) {
                window.scrollTo(0, 0);
            }
        }
    },
    beforeMount() {
        this.$root.$on("appointmentSearchResult", this.openAppointmentSearchResultDialog);
        this.$root.$on("findAppointment", this.findAppointment);
        this.$root.$on("showNewCustomerDialog", this.openNewCustomerDialog);
        this.$root.$on("customerCreateSuccess", this.hideNewCustomerDialog);
        this.$root.$on("customerCreateCancelled", this.hideNewCustomerDialog);
        this.$root.$on("cancelFirstAvailabilityDialog", this.closeFirstAvailabilityDialog);
        this.$root.$on("cancelSearchAppointmentDialog", this.closeAppointmentSearchDialog);
        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener("scroll", this.onWindowScroll, this.windowScrollOptions);
    },
    beforeDestroy() {
        this.$root.$off("appointmentSearchResult", this.openAppointmentSearchResultDialog);
        this.$root.$off("findAppointment", this.findAppointment);
        this.$root.$off("showNewCustomerDialog", this.openNewCustomerDialog);
        this.$root.$off("customerCreateSuccess", this.hideNewCustomerDialog);
        this.$root.$off("customerCreateCancelled", this.hideNewCustomerDialog);
        this.$root.$off("cancelFirstAvailabilityDialog", this.closeFirstAvailabilityDialog);
        this.$root.$off("cancelSearchAppointmentDialog", this.closeAppointmentSearchDialog);
        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("scroll", this.onWindowScroll, this.windowScrollOptions);
    },
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll", {
                load: true
            });
            this.employees = await this.$store.dispatch("employee/getAll", {
                load: true
            });
            this.employees.forEach((employee) => (employee.fullName = `${employee.name} ${employee.surname}`));
            this.services = await this.$store.dispatch("service/getAll", {
                load: true
            });
            this.calendarTypes = await this.$store.dispatch("calendarType/getAll");
            await this.$store.dispatch("customer/getAll", {
                load: true
            });
            this.rooms = await this.$store.dispatch("room/getAll", {
                load: true
            });
            this.equipments = await this.$store.dispatch("equipment/getAll", {
                load: true
            });
            this.resourcesGroupingData = [
                { id: ResourceGrouping.None, name: this.$t("calendar.none") },
                { id: ResourceGrouping.Employees, name: this.$t("calendar.employees") },
                { id: ResourceGrouping.Rooms, name: this.$t("calendar.rooms") },
                {
                    id: ResourceGrouping.Equipments,
                    name: this.$t("calendar.equipments")
                }
            ];
            setTimeout(() => {
                if (this.offices.length > 0) {
                    if (this.hasMultipleOffices) {
                        const lastOfficeId = localStorage.getItem("officeId");
                        if (lastOfficeId &&
                            this.offices.some(office => office.id == +lastOfficeId)) {
                            this.selectedOfficeId = +lastOfficeId;
                        }
                        else {
                            this.selectedOfficeId = this.offices[0].id;
                        }
                    }
                    else {
                        this.selectedOfficeId = this.getSingleOfficeId;
                    }
                }
            }, 0);
            setTimeout(async () => {
                for (const employee of this.employees) {
                    employee.image = await this.getEmployeeImage(employee.id);
                }
                this.refreshResources();
            }, 100);
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
        this.isReady = true;
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId",
            getEmployeeId: "account/getEmployeeId"
        }),
        availableServices() {
            if (this.services.length > 0)
                this.addPersonalNotes();
            return this.services.filter(service => service.deletedAt == undefined);
        },
        availableCalendarTypes() {
            return this.calendarTypes.filter(calendarType => calendarType.deletedAt == undefined);
        },
        calendarTypesData() {
            return this.availableCalendarTypes.filter(calendarType => calendarType.officeId === this.selectedOfficeId);
        },
        employeeSelector() {
            return this.getAvailableEmployees();
        },
        employeeAvailable: {
            get() {
                return this.$store.getters["employeeAvailable"];
            },
            set(val) {
                this.$store.commit("setEmployeeAvailable", val);
            }
        },
        employeeErrorMessage: {
            get() {
                return this.$store.getters["employeeErrorMessage"];
            },
            set(val) {
                this.$store.commit("setEmployeeErrorMessage", val);
            }
        },
        roomAvailable: {
            get() {
                return this.$store.getters["roomAvailable"];
            },
            set(val) {
                this.$store.commit("setRoomAvailable", val);
            }
        },
        roomErrorMessage: {
            get() {
                return this.$store.getters["roomErrorMessage"];
            },
            set(val) {
                this.$store.commit("setRoomErrorMessage", val);
            }
        },
        equipmentAvailable: {
            get() {
                return this.$store.getters["equipmentAvailable"];
            },
            set(val) {
                this.$store.commit("setEquipmentAvailable", val);
            }
        },
        equipmentErrorMessage: {
            get() {
                return this.$store.getters["equipmentErrorMessage"];
            },
            set(val) {
                this.$store.commit("setEquipmentErrorMessage", val);
            }
        },
        calendarStartHour() {
            if (this.startTime == null)
                return "00:00";
            return formatTime(this.appLocale, this.startTime);
        },
        calendarEndHour() {
            if (this.endTime == null)
                return "23:59";
            return formatTime(this.appLocale, this.endTime);
        }
    }
});
