import Vue from "vue";
export var ResourceType;
(function (ResourceType) {
    ResourceType[ResourceType["Employee"] = 0] = "Employee";
    ResourceType[ResourceType["Room"] = 1] = "Room";
    ResourceType[ResourceType["Equipment"] = 2] = "Equipment";
})(ResourceType || (ResourceType = {}));
export default Vue.extend({
    data() {
        return {
            data: {
                resourceData: {}
            },
        };
    }
});
