import Vue from "vue";
import LocaleMixin from "@/mixins/locale";
import { formatDate, formatTime } from "@/helpers/dateTimeHelper";
export default Vue.extend({
    mixins: [LocaleMixin],
    data() {
        return {
            data: {},
            customers: []
        };
    },
    computed: {
        getPaymentStatus() {
            return this.data.PaymentStatus == 1 ? "Non pagato" :
                this.data.PaymentStatus == 2 || this.data.PaymentStatus == 3 ?
                    (this.data.CustomerIds != null && this.data.CustomerIds.length > 0 ? "Pagato da:" : "Pagato") :
                    "Sconosciuto";
        }
    },
    methods: {
        formatDate(date) {
            return formatDate(this.appLocale, date);
        },
        formatTime(date) {
            return formatTime(this.appLocale, date);
        },
        getYesNoDescription(value) {
            return value ? "Sì" : "No";
        },
        getCustomerName(id) {
            return this.customers.find(customer => customer.id === id)?.fullName ?? "Cliente sconosciuto";
        }
    },
    async mounted() {
        this.customers = await this.$store.dispatch("customer/getAll", {
            load: false
        });
    }
});
