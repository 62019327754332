import Vue from "vue";
import AppointmentSearchToolbarDialogCard from "./AppointmentSearchToolbarDialogCard.vue";
export default Vue.extend({
    data() {
        return {
            animationSettings: { effect: "None" },
            buttons: [
                {
                    click: function () {
                        document.getElementById("appointment-dialog").ej2_instances[0].hide();
                    },
                    buttonModel: { content: this.$t('shared.close'), isPrimary: true }
                }
            ],
            cardListTemplate: function () {
                return { template: AppointmentSearchToolbarDialogCard };
            },
            appointments: {
                previous: undefined,
                next: undefined,
                data: []
            }
        };
    },
    methods: {
        onOpen(args) {
            args.element.style.maxWidth = "800px";
        },
        setAppointments(data) {
            this.appointments = data;
        },
        show() {
            this.$refs.appointmentDialog.show();
        },
        hide() {
            this.$refs.appointmentDialog.hide();
        }
    },
    beforeMount() {
        this.$root.$on("findAppointment", this.hide);
        this.$root.$on("appointmentSearchResult", this.setAppointments);
    },
    beforeDestroy() {
        this.$root.$off("findAppointment", this.hide);
        this.$root.$off("appointmentSearchResult", this.setAppointments);
    }
});
