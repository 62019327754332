import Vue from "vue";
import { L10n } from "@syncfusion/ej2-base";
import { RecurrenceEditorPlugin } from "@syncfusion/ej2-vue-schedule";
import CustomerComboBox from "@/components/Templates/Customer/CustomerComboBox.vue";
import CustomerMultiSelect from "@/components/Templates/Customer/CustomerMultiSelect.vue";
import LocaleMixin from "@/mixins/locale";
import { addHours, getFirstDayOfWeek } from "@/helpers/dateTimeHelper";
import CustomersNotesTemplate from "@/components/Templates/Calendar/CustomersNotesTemplate.vue";
import AppDropDownListAppointmentCancelType from "@/components/Inputs/AppDropDownListAppointmentCancelType.vue";
Vue.use(RecurrenceEditorPlugin);
const personalNotesServiceId = -1;
export default Vue.extend({
    props: {
        defaultOfficeId: {
            type: Number,
            required: false
        }
    },
    components: {
        "app-dropdownlist-appointment-cancel-type": AppDropDownListAppointmentCancelType,
        "customer-combobox": CustomerComboBox,
        "customer-multiselect": CustomerMultiSelect
    },
    mixins: [LocaleMixin],
    data() {
        return {
            firstDayOfWeek: getFirstDayOfWeek(this.appLocale),
            data: {
                Guid: "",
                ChildId: null,
                Id: null,
                OfficeId: null,
                CustomerId: null,
                CustomerNotes: null,
                CustomerCancelType: 0,
                CustomerIds: [],
                CustomersNotes: [],
                CustomersCancelType: [],
                ServiceId: null,
                CustomerHealthPathId: null,
                EmployeeId: null,
                IsTrial: null,
                Subject: "",
                OriginalStartTime: null,
                StartTime: null,
                EndTime: null,
                RoomId: null,
                EquipmentId: null,
                RecurrenceRule: null,
                RecurrenceID: null,
                RecurrenceException: null,
                RecurrenceExceptionDates: null,
                Location: null,
                Notes: null
            },
            customersNotesDataSource: [],
            customersCancelTypeDataSource: [],
            previousStartTime: null,
            offices: [],
            employees: [],
            customers: [],
            services: [],
            rooms: [],
            equipments: [],
            customerHealthPaths: [],
            customersNotes: [],
            currentAction: this.$parent.ej2Instances.currentAction,
            searchingHealthPaths: false,
            customersNotesDialogTemplate: function () {
                return {
                    template: {
                        extends: CustomersNotesTemplate,
                        propsData: { customers: [] }
                    }
                };
            }
        };
    },
    methods: {
        onStartTimeValueChanged(value) {
            if (this.previousStartTime != null && this.data.EndTime != null) {
                const difference = value.getTime() - this.previousStartTime.getTime();
                const endTime = new Date(this.data.EndTime.getTime() + difference);
                this.previousStartTime = value;
                this.data.EndTime = endTime;
            }
        },
        updateEndsOnDropDownData() {
            if (this.$refs["RecurrenceEditor"] == undefined)
                return;
            const recurrenceEditor = this.$refs["RecurrenceEditor"]
                .ej2Instances;
            const defaultLocale = {
                never: "Never",
                until: "Until",
                count: "Count"
            };
            const localeObj = new L10n("recurrenceeditor", defaultLocale, recurrenceEditor.locale);
            const endData = [
                //'never',
                "until",
                "count"
            ];
            const dataSource = [];
            endData.forEach(function (data) {
                dataSource.push({ text: localeObj.getConstant(data), value: data });
            });
            const endOnElement = recurrenceEditor.element.querySelector(".e-end-on-element");
            endOnElement.ej2_instances[0].dataSource = dataSource;
        },
        addPersonalNotes(services) {
            const hasPersonalNotes = services.some(service => service.id === personalNotesServiceId);
            if (!hasPersonalNotes) {
                const personalNote = {
                    name: this.$t("shared.notes"),
                    id: personalNotesServiceId,
                    price: 0,
                    description: "",
                    multipleParticipants: false,
                    roomRequired: false,
                    roomMandatory: false,
                    equipmentRequired: false,
                    equipmentMandatory: false,
                    offices: []
                };
                services.unshift(personalNote);
            }
            return services;
        },
        async getCustomerHealthPaths() {
            if (this.data.CustomerId == null)
                return;
            try {
                this.searchingHealthPaths = true;
                const healthPaths = await this.$store.dispatch("customer/getHealthPaths", {
                    customerId: this.data.CustomerId
                });
                this.customerHealthPaths = healthPaths.filter(healthPath => healthPath.id == this.data.CustomerHealthPathId || ((healthPath.deletedAt == null) &&
                    (healthPath.completedAt == null || new Date(healthPath.completedAt) > new Date()) &&
                    (healthPath.movedToGymAt == null || new Date(healthPath.movedToGymAt) > new Date())));
                if (this.customerHealthPaths.find(healthPath => healthPath.id == this.data.CustomerHealthPathId) == undefined)
                    this.data.CustomerHealthPathId = null;
            }
            catch (errors) {
                this.$toast.showWarningToast(this.$t("shared.toastFailureTitle"), errors[0].message);
            }
            finally {
                this.searchingHealthPaths = false;
            }
        },
        openCustomersNotesDialog() {
            const customersNotes = [...this.customersNotes];
            this.customersNotes = [];
            if (!this.isMultipleCustomers) {
                const customer = this.customers.find(customer => customer.id === this.data.CustomerId);
                if (customer != undefined) {
                    this.customersNotes.push({
                        id: customer.id,
                        name: customer.fullName,
                        notes: customersNotes.find(customerNotes => customerNotes.id === customer.id)?.notes ?? "",
                        cancelType: customersNotes.find(customerNotes => customerNotes.id === customer.id)?.cancelType ?? 0
                    });
                }
            }
            else {
                for (const customerId of this.data.CustomerIds ?? []) {
                    const customer = this.customers.find(customer => customer.id === customerId);
                    if (customer != undefined) {
                        this.customersNotes.push({
                            id: customer.id,
                            name: customer.fullName,
                            notes: customersNotes.find(customerNotes => customerNotes.id === customer.id)?.notes ?? "",
                            cancelType: customersNotes.find(customerNotes => customerNotes.id === customer.id)?.cancelType ?? 0
                        });
                    }
                }
            }
            const customers = this.customersNotes;
            this.customersNotesDialogTemplate = function () {
                return {
                    template: {
                        extends: CustomersNotesTemplate,
                        propsData: { customers: customers }
                    }
                };
            };
            this.$refs.CustomersNotesDialog.show();
        },
        submitCustomersNotes() {
            this.data.CustomerNotes = "";
            if (!this.isMultipleCustomers) {
                this.data.CustomerNotes = this.customersNotes.find(customerNotes => customerNotes.id === this.data.CustomerId)?.notes;
                this.data.CustomerCancelType = this.customersNotes.find(customerNotes => customerNotes.id === this.data.CustomerId)?.cancelType ?? 0;
            }
            else {
                this.customersNotesDataSource = [];
                this.customersCancelTypeDataSource = [];
                this.data.CustomersNotes = [];
                this.data.CustomersCancelType = [];
                for (const customerId of this.data.CustomerIds ?? []) {
                    const customerNotes = this.customersNotes.find(customerNotes => customerNotes.id === customerId);
                    this.customersNotesDataSource.push({
                        id: customerId,
                        text: `${customerId}_${customerNotes?.notes ?? ""}`
                    });
                    this.data.CustomersNotes.push(`${customerId}_${customerNotes?.notes ?? ""}`);
                    this.customersCancelTypeDataSource.push({
                        id: customerId,
                        text: `${customerId}_${customerNotes?.cancelType ?? 0}`
                    });
                    this.data.CustomersCancelType.push(`${customerId}_${customerNotes?.cancelType ?? 0}`);
                }
            }
            this.$refs.CustomersNotesDialog.hide();
        }
    },
    computed: {
        customersNotesDialogButtons() {
            return [{
                    buttonModel: { isPrimary: true, content: "Ok" },
                    click: this.submitCustomersNotes
                }];
        },
        employeeAvailable() {
            return this.$store.getters["employeeAvailable"];
        },
        employeeErrorMessage() {
            return this.$store.getters["employeeErrorMessage"];
        },
        roomAvailable() {
            return this.$store.getters["roomAvailable"];
        },
        roomErrorMessage() {
            return this.$store.getters["roomErrorMessage"];
        },
        equipmentAvailable() {
            return this.$store.getters["equipmentAvailable"];
        },
        equipmentErrorMessage() {
            return this.$store.getters["equipmentErrorMessage"];
        },
        employeesData() {
            if (this.data.OfficeId == null)
                return this.employees;
            const employees = this.employees.filter(employee => employee.services?.some(employeeService => employeeService.officeId === this.data.OfficeId));
            if (this.data.ServiceId == null || this.isPersonalNotes)
                return employees;
            return employees.filter(employee => employee.services?.some(employeeService => employeeService.serviceId === this.data.ServiceId));
        },
        servicesData() {
            if (this.data.OfficeId == null)
                return this.addPersonalNotes(this.services);
            const services = this.services.filter(service => service.offices?.some(serviceOffice => serviceOffice.officeId === this.data.OfficeId) || service.id == personalNotesServiceId);
            if (this.data.EmployeeId == null)
                return this.addPersonalNotes(services);
            const employee = this.employees.find(employee => employee.id === this.data.EmployeeId);
            if (employee == undefined)
                return this.addPersonalNotes(services);
            return this.addPersonalNotes(services.filter(service => employee.services?.some(employeeService => employeeService.serviceId === service.id) || service.id == personalNotesServiceId));
        },
        roomsData() {
            if (this.data.OfficeId == null)
                return this.rooms;
            return this.rooms.filter(room => room.officeId === this.data.OfficeId);
        },
        equipmentsData() {
            if (this.data.OfficeId == null)
                return this.equipments;
            return this.equipments.filter(equipment => equipment.officeId === this.data.OfficeId);
        },
        isPersonalNotes() {
            return this.data.ServiceId == personalNotesServiceId;
        },
        isMultipleCustomers() {
            if (this.data.ServiceId == null)
                return undefined;
            const service = this.services.find(service => service.id === this.data.ServiceId);
            if (service == undefined)
                return undefined;
            return service.multipleParticipants;
        },
        isRoomVisible() {
            if (this.data.ServiceId == null) {
                return false;
            }
            const service = this.services.find((service) => service.id === this.data.ServiceId);
            return service?.roomRequired ?? false;
        },
        isEquipmentVisible() {
            if (this.data.ServiceId == null) {
                return false;
            }
            const service = this.services.find((service) => service.id === this.data.ServiceId);
            return service?.equipmentRequired ?? false;
        },
        haveCustomerHealthPaths() {
            return this.data.CustomerId != null && this.customerHealthPaths.length > 0;
        }
    },
    async mounted() {
        this.offices = await this.$store.dispatch("office/getAll", {
            load: false
        });
        this.services = await this.$store.dispatch("service/getAll", {
            load: false
        });
        this.services = this.services.filter(service => service.deletedAt == undefined || service.id == this.data.ServiceId);
        this.customers = await this.$store.dispatch("customer/getAll", {
            load: false
        });
        this.customers = this.customers.filter(customer => customer.deletedAt == undefined || customer.id == this.data.CustomerId || this.data.CustomerIds?.includes(customer.id));
        this.customers.forEach(customer => customer.fullName = `${customer.name} ${customer.surname}`);
        this.employees = await this.$store.dispatch("employee/getAll", {
            load: false
        });
        this.employees = this.employees.filter(employee => employee.deletedAt == undefined || employee.id == this.data.EmployeeId);
        this.employees.forEach(employee => employee.fullName = `${employee.name} ${employee.surname}`);
        this.rooms = await this.$store.dispatch("room/getAll", {
            load: false
        });
        this.rooms = this.rooms.filter(room => room.deletedAt == undefined || room.id == this.data.RoomId);
        this.equipments = await this.$store.dispatch("equipment/getAll", {
            load: false
        });
        this.equipments = this.equipments.filter(equipment => equipment.deletedAt == undefined || equipment.id == this.data.EquipmentId);
        if (this.data.OfficeId == null)
            this.data.OfficeId = this.defaultOfficeId;
        if (this.data.CustomerIds == null)
            this.data.CustomerIds = [];
        if (this.data.Id == null && this.data.StartTime != null)
            this.data.EndTime = addHours(this.data.StartTime, 1);
        //if (this.data.Id === this.data.RecurrenceID) {
        if (this.currentAction == "EditOccurrence") {
            this.data.Id = null;
            this.data.RecurrenceRule = null;
            this.data.RecurrenceException = null;
            this.data.RecurrenceExceptionDates = null;
        }
        this.data.OriginalStartTime = this.data.StartTime;
        if (this.data.IsTrial === null)
            this.data.IsTrial = false;
        this.previousStartTime = this.data.StartTime;
        // Fix recurrence options:
        this.updateEndsOnDropDownData();
        if (!this.isMultipleCustomers) {
            const customer = this.customers.find(customer => customer.id === this.data.CustomerId);
            if (customer != undefined) {
                this.customersNotes.push({
                    id: customer.id,
                    name: customer.fullName,
                    notes: this.data.CustomerNotes,
                    cancelType: this.data.CustomerCancelType
                });
            }
        }
        else {
            const customersNotes = this.data.CustomersNotes?.map((customerNotes) => {
                const indexOfUnderscore = customerNotes.indexOf('_');
                return {
                    id: +customerNotes.slice(0, indexOfUnderscore),
                    notes: customerNotes.slice(indexOfUnderscore + 1)
                };
            }) ?? [];
            this.customersNotesDataSource = [];
            this.data.CustomersNotes = [];
            const customersCancelType = this.data.CustomersCancelType?.map((customerCancelType) => {
                const indexOfUnderscore = customerCancelType.indexOf('_');
                return {
                    id: +customerCancelType.slice(0, indexOfUnderscore),
                    cancelType: +customerCancelType.slice(indexOfUnderscore + 1)
                };
            }) ?? [];
            this.customersCancelTypeDataSource = [];
            this.data.CustomersCancelType = [];
            for (const customerId of this.data.CustomerIds ?? []) {
                const customer = this.customers.find(customer => customer.id === customerId);
                if (customer != undefined) {
                    const customerNotes = customersNotes.find(customerNotes => customerNotes.id === customer.id)?.notes ?? "";
                    this.customersNotesDataSource.push({
                        id: customer.id,
                        text: `${customer.id}_${customerNotes}`
                    });
                    const customerCancelType = customersCancelType.find(customerCancelType => customerCancelType.id === customer.id)?.cancelType ?? 0;
                    this.customersCancelTypeDataSource.push({
                        id: customer.id,
                        text: `${customer.id}_${customerCancelType}`
                    });
                    this.customersNotes.push({
                        id: customer.id,
                        name: customer.fullName,
                        notes: customerNotes,
                        cancelType: customerCancelType
                    });
                    this.data.CustomersNotes.push(`${customer.id}_${customerNotes}`);
                    this.data.CustomersCancelType.push(`${customer.id}_${customerCancelType}`);
                }
            }
        }
        // Add customer notes buttons tooltip
        const customerNotesButtonElement = document.getElementById("CustomerNotesButton");
        customerNotesButtonElement.setAttribute("title", "Dati aggiuntivi cliente");
        const customersNotesButtonElement = document.getElementById("CustomersNotesButton");
        customersNotesButtonElement.setAttribute("title", "Dati aggiuntivi clienti");
    }
});
