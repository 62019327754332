import Vue from "vue";
import { formatDate, formatTime } from "@/helpers/dateTimeHelper";
import LocaleMixin from "@/mixins/locale";
import SpinnerMixin from "@/mixins/spinner";
import { mapGetters } from "vuex";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
Vue.use(TooltipPlugin);
export default Vue.extend({
    mixins: [LocaleMixin, SpinnerMixin],
    data() {
        return {
            appointments: {
                previous: undefined,
                next: undefined,
                data: []
            },
            spinnerTarget: null,
            tooltipContent: ""
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        getYesNoValue(value) {
            return value ? "Sì" : "No";
        },
        isPastAppointment(appointment) {
            const currentDate = new Date(new Date().toDateString());
            return appointment.startTime < currentDate;
        },
        getChipValues(appointment) {
            if (appointment.paymentStatus == null)
                return [];
            return [{
                    text: appointment.paymentStatus == 1 ? "Non pagato" : appointment.paymentStatus == 2 || appointment.paymentStatus == 3 ? "Pagato" : "Sconosciuto",
                    cssClass: appointment.paymentStatus == 1 ? "e-danger" : appointment.paymentStatus == 2 ? "e-warning" : appointment.paymentStatus == 3 ? "e-success" : "",
                    leadingIcon: "far fa-credit-card",
                    data: {
                        id: appointment.id
                    }
                }];
        },
        tooltipOnBeforeRender(args) {
            const appointmentId = args.target.dataset.id;
            const appointment = this.appointments.data.find(appointment => appointment.id == appointmentId);
            if (appointment == undefined) {
                this.tooltipContent = "";
                return;
            }
            const hasMultipleCustomers = appointment.customerFullName != undefined && appointment.customerFullName.indexOf(',') >= 0;
            this.tooltipContent = appointment.paymentStatus == 1 ? "Non pagato" :
                appointment.paymentStatus == 2 || appointment.paymentStatus == 3 ?
                    (hasMultipleCustomers ? "Pagato da:" : "Pagato") :
                    "Stato sconosciuto";
            if (hasMultipleCustomers && appointment.paidCustomers != undefined && appointment.paidCustomers.length > 0) {
                for (const customerName of appointment.paidCustomers)
                    this.tooltipContent += `<div>${customerName}</div>`;
            }
        },
        setAppointments(value) {
            this.appointments = value;
            document.getElementById("appointments-content").parentElement.scrollTop = 0;
            this.hideSpinner(this.spinnerTarget);
        },
        setError(value) {
            this.hideSpinner(this.spinnerTarget);
        },
        formatDate(value) {
            return formatDate(this.appLocale, value);
        },
        formatTime(value) {
            return formatTime(this.appLocale, value);
        },
        findAppointment(appointment) {
            this.$root.$emit("findAppointment", appointment);
        },
        emitPreviousPage() {
            this.showSpinner(this.spinnerTarget);
            this.$root.$emit("appointmentPreviousPage", this.appointments.previous);
        },
        emitNextPage() {
            this.showSpinner(this.spinnerTarget);
            this.$root.$emit("appointmentNextPage", this.appointments.next);
        }
    },
    beforeMount() {
        this.$root.$on("appointmentSearchResult", this.setAppointments);
        this.$root.$on("appointmentSearchError", this.setError);
    },
    beforeDestroy() {
        this.$root.$off("appointmentSearchResult", this.setAppointments);
        this.$root.$off("appointmentSearchError", this.setError);
    },
    mounted() {
        this.spinnerTarget = document.getElementById("appointments-content");
        this.createSpinner(this.spinnerTarget);
    }
});
