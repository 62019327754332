import Vue from "vue";
import { GridPlugin, Edit, Toolbar, CommandColumn } from "@syncfusion/ej2-vue-grids";
import CnsCardReader from "@/mixins/cnsCardReader";
import SpinnerMixin from "@/mixins/spinner";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [SpinnerMixin, CnsCardReader],
    data() {
        return {
            title: this.$t("customers.create.title"),
            editSettings: {
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
                mode: "Dialog"
            },
            commands: [
                /*
                {
                  type: "Edit",
                  buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                */
                {
                    type: "Delete",
                    buttonOption: {
                        cssClass: "e-flat",
                        iconCss: "e-delete e-icons"
                    }
                }
            ],
            timeSlots: [],
            acquisitionChannels: [],
            customer: {
                name: "",
                surname: "",
                timeSlotId: null,
                acquisitionChannelId: null,
                services: []
            },
            createdCustomer: null,
            toolbar: ["Add"],
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        maxLength: 100
                    },
                    surname: {
                        required: true,
                        maxLength: 100
                    },
                    fiscalCode: {
                        maxLength: 16
                    },
                    phoneNumber: {
                        digits: true,
                        maxLength: 20
                    },
                    mobileNumber: {
                        digits: true,
                        maxLength: 20
                    },
                    email: {
                        email: true,
                        maxLength: 100
                    }
                }
            }
        };
    },
    provide: {
        grid: [Edit, Toolbar, CommandColumn]
    },
    methods: {
        async create() {
            this.createdCustomer = await this.$store.dispatch("customer/create", {
                customer: this.customer
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("customers.create.toastSuccessTitle"), this.$t("customers.create.toastSuccessContent", {
                name: `${this.customer.name} ${this.customer.surname}`
            }));
            const customerCopy = Object.assign({}, this.createdCustomer);
            this.$root.$emit("customerCreateSuccess", customerCopy);
            //return this.$router.push("/customers");
            this.clearFields();
        },
        cancel() {
            this.$root.$emit("customerCreateCancelled");
            //return this.$router.push("/customers");
            this.clearFields();
        },
        clearFields() {
            setTimeout(() => {
                this.customer = {
                    name: "",
                    surname: "",
                    email: null,
                    fiscalCode: null,
                    mobileNumber: null,
                    phoneNumber: null,
                    timeSlotId: null,
                    acquisitionChannelId: null,
                    notes: null
                };
            }, 200);
        },
        setData(data) {
            if (data == null) {
                this.$toast.showWarningToast(this.$t("csnCardReader.toastWarningTitle"), this.$t("csnCardReader.toastWarningContent"));
                return;
            }
            this.customer.name = data.firstName;
            this.customer.surname = data.lastName;
            this.customer.fiscalCode = data.ssn;
        }
    },
    async created() {
        try {
            this.acquisitionChannels = await this.$store.dispatch("acquisitionChannel/getAll");
            this.acquisitionChannels.unshift({
                id: undefined,
                name: this.$t("acquisitionChannels.none"),
                description: ""
            });
            this.timeSlots = await this.$store.dispatch("customerTimeSlot/getAll");
            this.timeSlots.unshift({
                id: undefined,
                name: this.$t("customersTimeSlots.none"),
                startTime: new Date(),
                endTime: new Date(),
                order: -1
            });
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
    },
    mounted() {
        document.getElementById("card-reader").focus();
        this.$on("cnsDataRead", this.setData);
    },
    beforeDestroy() {
        this.$off("cnsDataRead", this.setData);
    }
});
