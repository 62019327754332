import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
export class AppointmentsCustomUrlAdaptor extends CustomUrlAdaptor {
    constructor() {
        super(...arguments);
        this.cachedQuery = "";
    }
    processQuery(dm, query, hierarchyFilters) {
        query.addParams("ExcludeChildren", "true");
        if (this.isQueryCached(query) && this.cachedData) {
            return this.cachedData;
        }
        return super.processQuery(dm, query, hierarchyFilters);
    }
    isQueryCached(query) {
        return JSON.stringify(query) === this.cachedQuery;
    }
    processResponse(data, ds, query, xhr, request, changes) {
        const result = super.processResponse(data, ds, query, xhr, request, changes);
        this.cachedQuery = JSON.stringify(query);
        this.cachedData = result;
        return result;
    }
    insert(dm, data, tableName, query) {
        this.cachedQuery = "";
        this.cachedData = undefined;
        return super.insert(dm, data, tableName, query);
    }
    update(dm, keyField, value, tableName, query) {
        this.cachedQuery = "";
        this.cachedData = undefined;
        return super.update(dm, keyField, value, tableName, query);
    }
    remove(dm, keyField, value, tableName, query) {
        this.cachedQuery = "";
        this.cachedData = undefined;
        return super.remove(dm, keyField, value, tableName, query);
    }
    batchRequest(dm, changes, e, query, original) {
        this.cachedQuery = "";
        this.cachedData = undefined;
        return super.batchRequest(dm, changes, e, query, original);
    }
}
