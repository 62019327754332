import Vue from "vue";
import AppDropDownListAppointmentCancelType from "@/components/Inputs/AppDropDownListAppointmentCancelType.vue";
export default Vue.extend({
    components: {
        "app-dropdownlist-appointment-cancel-type": AppDropDownListAppointmentCancelType
    },
    props: {
        customers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {};
    }
});
