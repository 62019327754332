import Vue from "vue";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import FooterTemplate from "./CustomerComboBoxFooter.vue";
Vue.use(DropDownListPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            internalValue: null,
            dataSource: [],
            dataMsgContainerId: `${this.id}-error`,
            footerTemplate: function () {
                return { template: FooterTemplate };
            }
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        },
        addCustomer(customer) {
            customer.fullName = `${customer.name} ${customer.surname}`;
            const dropDownList = this.$refs[this.id];
            if (!dropDownList.ej2Instances.isDestroyed) {
                this.dataSource = [...this.dataSource, customer];
                setTimeout(() => {
                    this.internalValue = customer.id;
                    dropDownList.hidePopup();
                }, 200);
            }
        },
        getCustomerName(value) {
            const dropDownList = this.$refs[this.id];
            const customer = dropDownList.getDataByValue(value);
            if (customer == null)
                return "";
            return customer.fullName;
        },
        filtering(e) {
            let query = new Query();
            const terms = e.text.split(" ").filter((t) => t != "");
            if (terms.length > 0) {
                let predicate = null;
                for (const term of terms) {
                    const termPredicate = new Predicate("fullName", "contains", term, true, true);
                    if (predicate == null)
                        predicate = termPredicate;
                    else
                        predicate = predicate.and(termPredicate);
                }
                query = query.where(predicate);
            }
            e.updateData(this.dataSource, query);
        }
    },
    async mounted() {
        const combobox = this.$refs[this.id];
        combobox.showSpinner();
        let customers = await this.$store.dispatch("customer/getAll", {
            load: false
        });
        customers = customers.filter(customer => customer.deletedAt == undefined || customer.id == this.value);
        customers.forEach((customer) => (customer.fullName = `${customer.name} ${customer.surname}`));
        this.dataSource = customers;
        combobox.hideSpinner();
        this.internalValue = this.value;
        this.$root.$on("customerCreateSuccess", this.addCustomer);
    },
    beforeDestroy() {
        this.$root.$off("customerCreateSuccess", this.addCustomer);
    }
});
