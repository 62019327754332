import Vue from "vue";
export default Vue.extend({
    data() {
        return {};
    },
    methods: {
        showCustomerCreateForm() {
            this.$root.$emit("showNewCustomerDialog");
        }
    },
});
