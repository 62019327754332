import Vue from "vue";
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import FooterTemplate from "./CustomerComboBoxFooter.vue";
import { Query, Predicate } from "@syncfusion/ej2-data";
Vue.use(MultiSelectPlugin);
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            required: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            internalValue: [],
            dataSource: [],
            dataMsgContainerId: `${this.id}-error`,
            footerTemplate: function () {
                return { template: FooterTemplate };
            }
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        },
        addCustomer(customer) {
            const obj = Object.assign({}, {
                id: customer.id,
                fullName: `${customer.name} ${customer.surname}`
            });
            //customer.fullName = `${customer.name} ${customer.surname}`;
            //const dropDownList = (this.$refs[this.id] as any) as MultiSelect;
            const dropDownList = this.$refs[this.id];
            if (!dropDownList.ej2Instances.isDestroyed) {
                //dropDownList.hidePopup();
                //dropDownList.addItem(obj);
                this.dataSource = [...this.dataSource, obj];
                setTimeout(() => this.internalValue = [...this.internalValue, customer.id], 200);
                //dropDownList.ej2Instances.value = [...dropDownList.ej2Instances.value, customer.id];
            }
        },
        getCustomerName(value) {
            const dropDownList = this.$refs[this.id];
            const customer = dropDownList.getDataByValue(value);
            if (customer == null)
                return "";
            return customer.fullName;
        },
        filtering(e) {
            let query = new Query();
            const terms = e.text.split(" ").filter((t) => t != "");
            if (terms.length > 0) {
                let predicate = null;
                for (const term of terms) {
                    const termPredicate = new Predicate("fullName", "contains", term, true, true);
                    if (predicate == null)
                        predicate = termPredicate;
                    else
                        predicate = predicate.and(termPredicate);
                }
                query = query.where(predicate);
            }
            e.updateData(this.dataSource, query);
        }
    },
    async mounted() {
        const combobox = this.$refs[this.id];
        combobox.showSpinner();
        let customers = await this.$store.dispatch("customer/getAll", {
            load: false
        });
        customers = customers.filter(customer => customer.deletedAt == undefined || this.value?.some(customerId => customer.id == customerId));
        const source = [];
        customers.forEach((customer) => {
            const obj = Object.assign({}, {
                id: customer.id,
                fullName: `${customer.name} ${customer.surname}`
            });
            source.push(obj);
            //(customer.fullName = `${customer.name} ${customer.surname}`)
        });
        if (!combobox.ej2Instances.isDestroyed) {
            this.dataSource = source;
            this.internalValue = this.value;
            this.$root.$on("customerCreateSuccess", this.addCustomer);
        }
        combobox.hideSpinner();
    },
    beforeDestroy() {
        this.$root.$off("customerCreateSuccess", this.addCustomer);
    }
});
